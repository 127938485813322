<template>
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 872 527">
        <defs>
            <linearGradient id="linear-gradient" y1="0.5" x2="1" y2="0.5" gradientUnits="objectBoundingBox">
            <stop offset="0" stop-color="#dbebff"/>
            <stop offset="0.527" stop-color="#dcecff"/>
            <stop offset="0.737" stop-color="#e3efff"/>
            <stop offset="0.889" stop-color="#f0f6ff"/>
            <stop offset="1" stop-color="#fff"/>
            </linearGradient>
            <linearGradient id="linear-gradient-2" x1="0.5" y1="0.931" x2="0.5" y2="0.263" gradientUnits="objectBoundingBox">
            <stop offset="0" stop-color="#aaceff"/>
            <stop offset="1" stop-color="#dbe9ff"/>
            </linearGradient>
            <linearGradient id="linear-gradient-3" x1="0.5" y1="0.952" x2="0.5" y2="0.24" gradientUnits="objectBoundingBox">
            <stop offset="0" stop-color="#aaceff"/>
            <stop offset="0.04" stop-color="#b0d1ff"/>
            <stop offset="0.188" stop-color="#c3dcff"/>
            <stop offset="0.361" stop-color="#d0e3ff"/>
            <stop offset="0.58" stop-color="#d8e7ff"/>
            <stop offset="1" stop-color="#dbe9ff"/>
            </linearGradient>
            <linearGradient id="linear-gradient-4" x1="0" y1="0.5" x2="1" y2="0.5" xlink:href="#linear-gradient-2"/>
            <linearGradient id="linear-gradient-5" x1="-2.741" y1="0.771" x2="3.741" y2="0.229" xlink:href="#linear-gradient-2"/>
            <linearGradient id="linear-gradient-6" x1="0.5" y1="0.261" x2="0.5" y2="0.9" xlink:href="#linear-gradient-2"/>
            <linearGradient id="linear-gradient-7" x1="0.5" y1="0.644" x2="0.5" y2="0.438" xlink:href="#linear-gradient-2"/>
            <linearGradient id="linear-gradient-8" x1="0.5" y1="0" x2="0.5" y2="1" xlink:href="#linear-gradient-3"/>
            <linearGradient id="linear-gradient-13" x1="-0.17" y1="0.899" x2="1.17" y2="0.101" xlink:href="#linear-gradient-2"/>
            <linearGradient id="linear-gradient-14" x1="-0.17" y1="0.899" x2="1.17" y2="0.101" xlink:href="#linear-gradient-2"/>
            <linearGradient id="linear-gradient-15" x1="-0.17" y1="0.899" x2="1.17" y2="0.101" xlink:href="#linear-gradient-2"/>
            <linearGradient id="linear-gradient-19" x1="-0.206" y1="0.887" x2="1.206" y2="0.113" xlink:href="#linear-gradient-2"/>
            <linearGradient id="linear-gradient-20" x1="-0.17" y1="0.899" x2="1.17" y2="0.101" xlink:href="#linear-gradient-2"/>
            <linearGradient id="linear-gradient-22" x1="-0.015" y1="0.807" x2="1.17" y2="0.101" xlink:href="#linear-gradient-2"/>
            <linearGradient id="linear-gradient-29" x1="-0.171" y1="0.899" x2="1.171" y2="0.101" xlink:href="#linear-gradient-2"/>
            <linearGradient id="linear-gradient-31" x1="-0.17" y1="0.899" x2="1.17" y2="0.101" xlink:href="#linear-gradient-2"/>
            <linearGradient id="linear-gradient-33" x1="-0.171" y1="0.898" x2="1.171" y2="0.102" xlink:href="#linear-gradient-2"/>
            <linearGradient id="linear-gradient-42" x1="0.5" y1="0.783" x2="0.5" y2="0.108" xlink:href="#linear-gradient"/>
            <linearGradient id="linear-gradient-52" x1="0.5" y1="1" x2="0.5" y2="0" xlink:href="#linear-gradient-2"/>
            <linearGradient id="linear-gradient-58" x1="0.232" y1="1.804" x2="0.769" y2="-0.072" xlink:href="#linear-gradient-2"/>
            <linearGradient id="linear-gradient-59" x1="0.232" y1="1.805" x2="0.769" y2="-0.072" xlink:href="#linear-gradient-2"/>
            <linearGradient id="linear-gradient-60" x1="0.232" y1="1.804" x2="0.769" y2="-0.072" xlink:href="#linear-gradient-2"/>
            <linearGradient id="linear-gradient-61" x1="0.232" y1="1.804" x2="0.769" y2="-0.072" xlink:href="#linear-gradient-2"/>
            <linearGradient id="linear-gradient-62" x1="0.232" y1="1.804" x2="0.769" y2="-0.073" xlink:href="#linear-gradient-2"/>
            <linearGradient id="linear-gradient-63" x1="0.232" y1="1.803" x2="0.769" y2="-0.072" xlink:href="#linear-gradient-2"/>
            <linearGradient id="linear-gradient-64" x1="0.232" y1="1.804" x2="0.769" y2="-0.073" xlink:href="#linear-gradient-2"/>
            <linearGradient id="linear-gradient-65" x1="0.232" y1="1.804" x2="0.769" y2="-0.073" xlink:href="#linear-gradient-2"/>
            <linearGradient id="linear-gradient-66" x1="0.5" y1="-0.008" x2="0.5" y2="1.008" xlink:href="#linear-gradient-3"/>
            <linearGradient id="linear-gradient-67" x1="0.5" y1="0.877" x2="0.5" y2="0.435" xlink:href="#linear-gradient"/>
            <linearGradient id="linear-gradient-68" x1="0.467" y1="0.894" x2="0.467" y2="0.272" xlink:href="#linear-gradient-2"/>
            <linearGradient id="linear-gradient-69" x1="0.5" y1="0.894" x2="0.5" y2="0.362" xlink:href="#linear-gradient"/>
            <linearGradient id="linear-gradient-72" x1="0.369" y1="0.5" x2="0.631" y2="0.5" xlink:href="#linear-gradient-2"/>
            <linearGradient id="linear-gradient-73" x1="-0.199" y1="0.889" x2="1.2" y2="0.111" xlink:href="#linear-gradient-2"/>
            <linearGradient id="linear-gradient-74" x1="-0.2" y1="0.889" x2="1.2" y2="0.111" xlink:href="#linear-gradient-2"/>
            <linearGradient id="linear-gradient-75" x1="-0.2" y1="0.889" x2="1.2" y2="0.111" xlink:href="#linear-gradient-2"/>
            <linearGradient id="linear-gradient-76" x1="-0.199" y1="0.889" x2="1.199" y2="0.111" xlink:href="#linear-gradient-2"/>
            <linearGradient id="linear-gradient-77" x1="-0.199" y1="0.889" x2="1.199" y2="0.111" xlink:href="#linear-gradient-2"/>
            <linearGradient id="linear-gradient-79" x1="-0.2" y1="0.889" x2="1.2" y2="0.111" xlink:href="#linear-gradient-2"/>
            <linearGradient id="linear-gradient-80" x1="-0.2" y1="0.889" x2="1.2" y2="0.111" xlink:href="#linear-gradient-2"/>
            <linearGradient id="linear-gradient-81" x1="-0.199" y1="0.889" x2="1.199" y2="0.111" xlink:href="#linear-gradient-2"/>
            <linearGradient id="linear-gradient-82" x1="-0.199" y1="0.889" x2="1.199" y2="0.111" xlink:href="#linear-gradient-2"/>
            <linearGradient id="linear-gradient-83" x1="0.223" y1="3.12" x2="0.777" y2="-2.12" xlink:href="#linear-gradient-2"/>
            <linearGradient id="linear-gradient-84" x1="0.445" y1="0.5" x2="0.555" y2="0.5" xlink:href="#linear-gradient-2"/>
            <linearGradient id="linear-gradient-89" x1="-0.199" y1="0.889" x2="1.199" y2="0.111" xlink:href="#linear-gradient-2"/>
            <linearGradient id="linear-gradient-90" x1="0.5" y1="0.902" x2="0.5" y2="0.453" xlink:href="#linear-gradient"/>
            <linearGradient id="linear-gradient-91" x1="0.5" y1="0.345" x2="0.5" y2="0.614" xlink:href="#linear-gradient-2"/>
            <linearGradient id="linear-gradient-92" x1="0.659" y1="0.615" x2="0.298" y2="0.355" xlink:href="#linear-gradient"/>
            <linearGradient id="linear-gradient-93" x1="0.655" y1="0.739" x2="0.264" y2="0.216" xlink:href="#linear-gradient"/>
            <linearGradient id="linear-gradient-94" y1="0.5" x2="1" y2="0.5" gradientUnits="objectBoundingBox">
            <stop offset="0" stop-color="#fff"/>
            <stop offset="0.658" stop-color="#7fb6ea"/>
            <stop offset="1" stop-color="#4293e0"/>
            </linearGradient>
            <linearGradient id="linear-gradient-97" x1="0.852" y1="0.5" x2="0.445" y2="0.5" gradientUnits="objectBoundingBox">
            <stop offset="0" stop-color="#dbebff"/>
            <stop offset="0.361" stop-color="#e4f0ff"/>
            <stop offset="0.953" stop-color="#fcfdff"/>
            <stop offset="1" stop-color="#fff"/>
            </linearGradient>
            <linearGradient id="linear-gradient-98" x1="0.925" y1="0.214" x2="0.401" y2="1.001" xlink:href="#linear-gradient-97"/>
            <linearGradient id="linear-gradient-99" x1="1.172" y1="0.5" x2="0.253" y2="0.5" xlink:href="#linear-gradient"/>
            <linearGradient id="linear-gradient-102" x1="0.826" y1="0.63" x2="0.266" y2="0.335" xlink:href="#linear-gradient"/>
            <linearGradient id="linear-gradient-103" x1="0.643" y1="0.757" x2="0.319" y2="0.175" xlink:href="#linear-gradient"/>
            <linearGradient id="linear-gradient-108" x1="0.878" y1="0.188" x2="0.331" y2="0.897" xlink:href="#linear-gradient-97"/>
            <linearGradient id="linear-gradient-110" y1="0.5" x2="1" y2="0.5" gradientUnits="objectBoundingBox">
            <stop offset="0.007" stop-color="#2e62be"/>
            <stop offset="0.319" stop-color="#1d4eb0"/>
            <stop offset="0.725" stop-color="#0d3aa3"/>
            <stop offset="1" stop-color="#08339f"/>
            </linearGradient>
            <radialGradient id="radial-gradient" cx="0.5" cy="0.5" r="1.971" gradientTransform="translate(0.363 0.451) scale(0.274 0.098)" gradientUnits="objectBoundingBox">
            <stop offset="0" stop-color="#03327d"/>
            <stop offset="0.09" stop-color="#0f3c83"/>
            <stop offset="0.256" stop-color="#315795"/>
            <stop offset="0.48" stop-color="#6884b1"/>
            <stop offset="0.75" stop-color="#b3c1d8"/>
            <stop offset="1" stop-color="#fff"/>
            </radialGradient>
            <radialGradient id="radial-gradient-2" cx="0.5" cy="0.5" r="1.838" gradientTransform="translate(0.352 0.451) scale(0.295 0.098)" xlink:href="#radial-gradient"/>
            <linearGradient id="linear-gradient-111" x1="0.5" y1="0.3" x2="0.5" y2="0.845" xlink:href="#linear-gradient-110"/>
            <linearGradient id="linear-gradient-112" x1="0.5" y1="0.16" x2="0.5" y2="0.659" gradientUnits="objectBoundingBox">
            <stop offset="0" stop-color="#dbe9ff"/>
            <stop offset="1" stop-color="#aaceff"/>
            </linearGradient>
            <linearGradient id="linear-gradient-113" x1="0.67" y1="0.703" x2="0.233" y2="0.37" gradientUnits="objectBoundingBox">
            <stop offset="0" stop-color="#4293e0"/>
            <stop offset="0.418" stop-color="#7fb5ec"/>
            <stop offset="1" stop-color="#dbe9ff"/>
            </linearGradient>
            <linearGradient id="linear-gradient-114" x1="0.5" y1="0.657" x2="0.5" y2="0.148" xlink:href="#linear-gradient-113"/>
            <linearGradient id="linear-gradient-115" x1="0.984" y1="0.426" x2="0.227" y2="0.542" gradientUnits="objectBoundingBox">
            <stop offset="0" stop-color="#aaceff"/>
            <stop offset="0.727" stop-color="#e6f1ff"/>
            <stop offset="1" stop-color="#fff"/>
            </linearGradient>
            <linearGradient id="linear-gradient-116" x1="1.054" y1="0.377" x2="0.199" y2="0.567" xlink:href="#linear-gradient-113"/>
            <linearGradient id="linear-gradient-117" x1="0.793" y1="0.435" x2="0.017" y2="0.608" gradientUnits="objectBoundingBox">
            <stop offset="0" stop-color="#ff5900"/>
            <stop offset="0.193" stop-color="#ff6000"/>
            <stop offset="0.478" stop-color="#ff7400"/>
            <stop offset="0.819" stop-color="#ff9400"/>
            <stop offset="0.993" stop-color="#ffa800"/>
            </linearGradient>
            <linearGradient id="linear-gradient-118" x1="0.985" y1="0.425" x2="-0.11" y2="0.594" xlink:href="#linear-gradient-115"/>
            <linearGradient id="linear-gradient-119" x1="0.65" y1="0.455" x2="0.035" y2="0.638" xlink:href="#linear-gradient-113"/>
            <linearGradient id="linear-gradient-120" x1="0.673" y1="0.461" x2="-0.642" y2="0.754" xlink:href="#linear-gradient-117"/>
            <linearGradient id="linear-gradient-121" x1="0.985" y1="0.425" x2="-1.463" y2="0.802" xlink:href="#linear-gradient-115"/>
            <linearGradient id="linear-gradient-122" x1="-0.278" y1="0.663" x2="-5.739" y2="1.676" xlink:href="#linear-gradient-117"/>
            <linearGradient id="linear-gradient-123" x1="0.326" y1="0.5" x2="-0.007" y2="0.5" xlink:href="#linear-gradient-2"/>
            <linearGradient id="linear-gradient-124" x1="0.976" y1="0.439" x2="0.264" y2="0.557" xlink:href="#linear-gradient-113"/>
            <linearGradient id="linear-gradient-125" x1="0.778" y1="0.173" x2="0.054" y2="0.649" xlink:href="#linear-gradient-117"/>
            <linearGradient id="linear-gradient-128" x1="0.424" y1="0.5" x2="0.936" y2="0.5" xlink:href="#linear-gradient-117"/>
            <linearGradient id="linear-gradient-129" y1="0.5" x2="1" y2="0.5" gradientUnits="objectBoundingBox">
            <stop offset="0" stop-color="#000326"/>
            <stop offset="0.132" stop-color="#00062d"/>
            <stop offset="0.327" stop-color="#001141"/>
            <stop offset="0.561" stop-color="#002261"/>
            <stop offset="0.824" stop-color="#00398e"/>
            <stop offset="0.993" stop-color="#004bb0"/>
            </linearGradient>
            <linearGradient id="linear-gradient-131" x1="1.311" y1="0.287" x2="-0.104" y2="0.689" xlink:href="#linear-gradient-117"/>
            <linearGradient id="linear-gradient-132" x1="0.5" y1="0.256" x2="0.5" y2="0.671" xlink:href="#linear-gradient-117"/>
            <linearGradient id="linear-gradient-135" x1="0.559" y1="0.413" x2="-0.667" y2="0.918" xlink:href="#linear-gradient-110"/>
            <linearGradient id="linear-gradient-138" x1="1.008" y1="-1.931" x2="0.426" y2="0.647" xlink:href="#linear-gradient-110"/>
            <linearGradient id="linear-gradient-139" x1="0.815" y1="0.5" x2="0.244" y2="0.5" xlink:href="#linear-gradient-117"/>
            <linearGradient id="linear-gradient-140" x1="0.5" y1="0.059" x2="0.5" y2="0.918" xlink:href="#linear-gradient-110"/>
            <linearGradient id="linear-gradient-141" x1="0.797" y1="0.834" x2="0.167" y2="0.148" xlink:href="#linear-gradient-129"/>
            <linearGradient id="linear-gradient-142" x1="0.872" y1="0.764" x2="0.163" y2="0.343" gradientUnits="objectBoundingBox">
            <stop offset="0" stop-color="#08339f"/>
            <stop offset="0.275" stop-color="#0d3aa3"/>
            <stop offset="0.681" stop-color="#1d4eb0"/>
            <stop offset="0.993" stop-color="#2e62be"/>
            </linearGradient>
            <linearGradient id="linear-gradient-143" x1="0.813" y1="0.634" x2="0.123" y2="0.338" xlink:href="#linear-gradient-142"/>
        </defs>
        <g id="Group_3227" data-name="Group 3227" transform="translate(-698 -1031)">
            <rect id="Rectangle_4902" data-name="Rectangle 4902" width="871.707" height="526.767" transform="translate(698 1031)" fill="#fff"/>
            <g id="Group_3165" data-name="Group 3165" transform="translate(699 1031)">
            <path id="Path_424" data-name="Path 424" d="M1018.009,164.227,507.356,133.966c-32.3-1.914-57.834,26.446-51.888,57.631l64.774,339.73c4.965,26.035,30.22,43.425,56.884,39.166l440.883-70.4Z" transform="translate(-162.818 -133.874)" fill="url(#linear-gradient)"/>
            <g id="Group_3033" data-name="Group 3033" transform="translate(325.597 10.297)">
                <g id="Group_2992" data-name="Group 2992" transform="translate(434.114)">
                <path id="Path_425" data-name="Path 425" d="M995.175,488.2l.216-78.229-20.045-.081-.216,78.31Z" transform="translate(-922.529 -144.171)" fill="#7bb4f1"/>
                <path id="Path_426" data-name="Path 426" d="M996.123,144.171l-.453,164.544-20.045-.081.418-58.7h-.19Z" transform="translate(-922.529 -144.171)" fill="#c0d9fd"/>
                <path id="Path_427" data-name="Path 427" d="M922.529,308.42V409.676l52.817.214.278-101.255Z" transform="translate(-922.529 -144.171)" fill="#c0d9fd"/>
                <path id="Path_428" data-name="Path 428" d="M975.131,488.2l.216-78.31-52.817-.214V488.2Z" transform="translate(-922.529 -144.171)" fill="url(#linear-gradient-2)"/>
                <path id="Path_429" data-name="Path 429" d="M922.529,249.723v58.7l53.1.215.418-58.7Z" transform="translate(-922.529 -144.171)" fill="url(#linear-gradient-3)"/>
                <path id="Path_430" data-name="Path 430" d="M995.67,308.716l-.279,101.255-20.045-.081.279-101.255Z" transform="translate(-922.529 -144.171)" fill="url(#linear-gradient-4)"/>
                <path id="Path_431" data-name="Path 431" d="M951.37,357.363l-.1,35.085-2.835-.011.1-35.086Z" transform="translate(-922.529 -144.171)" fill="url(#linear-gradient-5)"/>
                <path id="Path_432" data-name="Path 432" d="M950.752,261.637l-.1,35.1-2.834-.012.1-35.1Z" transform="translate(-922.529 -144.171)" fill="#c0d9fd"/>
                <path id="Path_433" data-name="Path 433" d="M966.363,308.431l-.1,35.1-2.835-.011.1-35.1Z" transform="translate(-922.529 -144.171)" fill="url(#linear-gradient-6)"/>
                <path id="Path_434" data-name="Path 434" d="M934.508,319.255l-.1,35.1-2.829-.012.1-35.1Z" transform="translate(-922.529 -144.171)" fill="#7bb4f1"/>
                <path id="Path_435" data-name="Path 435" d="M964.9,374.58l-.1,35.1-2.829-.012.1-35.1Z" transform="translate(-922.529 -144.171)" fill="#7bb4f1"/>
                <path id="Path_436" data-name="Path 436" d="M976.043,249.939,996.1,144.171H941.534l-19,105.33v.138Z" transform="translate(-922.529 -144.171)" fill="#faf9fc"/>
                </g>
                <g id="Group_2994" data-name="Group 2994" transform="translate(0 239.726)">
                <path id="Path_437" data-name="Path 437" d="M534.613,424.077c29.052-22.536,8.139-39.7,8.139-39.7-5.405,33.886-33.781,21.564-49.1,39.7s6.307,47.837,21.62,60.416S537.428,542,537.428,542h9.008s11.035-35.17,3.6-61.063S511.995,441.623,534.613,424.077Z" transform="translate(-488.415 -384.076)" fill="url(#linear-gradient-7)"/>
                <g id="Group_2993" data-name="Group 2993" transform="translate(15.171)">
                    <path id="Path_438" data-name="Path 438" d="M542.284,384.674a16.506,16.506,0,0,1,1.437,12.4c-2.159,8.56-10.059,16.628-23.48,23.979-10.386,5.687-15.8,11.587-16.564,18.039-1.177,9.985,9.333,19.387,18.608,27.686a115.59,115.59,0,0,1,9.959,9.594c13.541,15.624,9.372,65.077,9.328,65.575l1.112.094c.18-2.05,4.252-50.406-9.59-66.378a116.3,116.3,0,0,0-10.057-9.7c-9.075-8.119-19.364-17.323-18.251-26.75.717-6.078,5.95-11.706,16-17.211,13.718-7.512,21.8-15.826,24.027-24.7,1.953-7.8-1.45-13.013-1.6-13.231Z" transform="translate(-503.586 -384.076)" fill="url(#linear-gradient-8)"/>
                </g>
                </g>
                <g id="Group_2995" data-name="Group 2995" transform="translate(239.805 113.756)">
                <rect id="Rectangle_4637" data-name="Rectangle 4637" width="77.975" height="6.765" transform="translate(3.684 79.131)" fill="#c0d9fd"/>
                <rect id="Rectangle_4638" data-name="Rectangle 4638" width="77.286" height="6.766" transform="translate(4.372 166.268)" fill="#c0d9fd"/>
                <path id="Path_439" data-name="Path 439" d="M728.22,431.227v81.268h81.418l.509.086V431.186Z" transform="translate(-728.22 -258.161)" fill="url(#linear-gradient-4)"/>
                <path id="Path_440" data-name="Path 440" d="M728.22,344.06v80.45l81.927-.09V344.06Z" transform="translate(-728.22 -258.161)" fill="url(#linear-gradient-4)"/>
                <rect id="Rectangle_4639" data-name="Rectangle 4639" width="81.927" height="79.132" fill="url(#linear-gradient-4)"/>
                <path id="Path_441" data-name="Path 441" d="M728.22,585.234V519.261h81.418l.509.089v65.885Z" transform="translate(-728.22 -258.161)" fill="url(#linear-gradient-4)"/>
                <rect id="Rectangle_4640" data-name="Rectangle 4640" width="77.505" height="6.766" transform="translate(3.684 254.339)" fill="#c0d9fd"/>
                <rect id="Rectangle_4641" data-name="Rectangle 4641" width="6.076" height="10.208" transform="translate(69.469 114.037)" fill="#7bb4f1"/>
                <rect id="Rectangle_4642" data-name="Rectangle 4642" width="6.076" height="10.207" transform="translate(69.469 96.736)" fill="#7bb4f1"/>
                <rect id="Rectangle_4643" data-name="Rectangle 4643" width="6.075" height="10.208" transform="translate(68.976 289.245)" fill="#7bb4f1"/>
                <rect id="Rectangle_4644" data-name="Rectangle 4644" width="6.075" height="10.207" transform="translate(68.976 271.944)" fill="#7bb4f1"/>
                <rect id="Rectangle_4645" data-name="Rectangle 4645" width="6.076" height="10.207" transform="translate(68.754 26.9)" fill="#7bb4f1"/>
                <rect id="Rectangle_4646" data-name="Rectangle 4646" width="5.594" height="10.208" transform="translate(63.304 9.599)" fill="#7bb4f1"/>
                <rect id="Rectangle_4647" data-name="Rectangle 4647" width="6.076" height="10.208" transform="translate(68.584 147.428)" fill="#7bb4f1"/>
                <rect id="Rectangle_4648" data-name="Rectangle 4648" width="6.076" height="10.208" transform="translate(68.584 130.126)" fill="#7bb4f1"/>
                <rect id="Rectangle_4649" data-name="Rectangle 4649" width="6.075" height="10.216" transform="translate(68.261 202.099)" fill="#7bb4f1"/>
                <rect id="Rectangle_4650" data-name="Rectangle 4650" width="6.075" height="10.216" transform="translate(68.261 184.798)" fill="#7bb4f1"/>
                <rect id="Rectangle_4651" data-name="Rectangle 4651" width="6.075" height="10.217" transform="translate(68.091 305.326)" fill="#7bb4f1"/>
                <rect id="Rectangle_4652" data-name="Rectangle 4652" width="6.076" height="10.216" transform="translate(67.869 60.282)" fill="#7bb4f1"/>
                <rect id="Rectangle_4653" data-name="Rectangle 4653" width="6.076" height="10.216" transform="translate(67.869 42.981)" fill="#7bb4f1"/>
                <rect id="Rectangle_4654" data-name="Rectangle 4654" width="6.075" height="10.217" transform="translate(67.376 235.49)" fill="#7bb4f1"/>
                <rect id="Rectangle_4655" data-name="Rectangle 4655" width="6.075" height="10.217" transform="translate(67.376 218.189)" fill="#7bb4f1"/>
                <rect id="Rectangle_4656" data-name="Rectangle 4656" width="6.075" height="10.208" transform="translate(46.396 114.037)" fill="url(#linear-gradient-13)"/>
                <rect id="Rectangle_4657" data-name="Rectangle 4657" width="6.075" height="10.207" transform="translate(46.396 96.736)" fill="#7bb4f1"/>
                <rect id="Rectangle_4658" data-name="Rectangle 4658" width="6.075" height="10.208" transform="translate(45.903 289.245)" fill="#7bb4f1"/>
                <rect id="Rectangle_4659" data-name="Rectangle 4659" width="6.075" height="10.207" transform="translate(45.903 271.944)" fill="url(#linear-gradient-14)"/>
                <rect id="Rectangle_4660" data-name="Rectangle 4660" width="6.075" height="10.207" transform="translate(45.682 26.9)" fill="#7bb4f1"/>
                <rect id="Rectangle_4661" data-name="Rectangle 4661" width="5.594" height="10.208" transform="translate(42.061 9.599)" fill="#7bb4f1"/>
                <rect id="Rectangle_4662" data-name="Rectangle 4662" width="6.075" height="10.208" transform="translate(45.512 147.428)" fill="#7bb4f1"/>
                <rect id="Rectangle_4663" data-name="Rectangle 4663" width="6.075" height="10.208" transform="translate(45.512 130.126)" fill="#7bb4f1"/>
                <rect id="Rectangle_4664" data-name="Rectangle 4664" width="6.075" height="10.216" transform="translate(45.188 202.099)" fill="#7bb4f1"/>
                <rect id="Rectangle_4665" data-name="Rectangle 4665" width="6.075" height="10.216" transform="translate(45.188 184.798)" fill="#7bb4f1"/>
                <rect id="Rectangle_4666" data-name="Rectangle 4666" width="6.076" height="10.217" transform="translate(45.018 305.326)" fill="url(#linear-gradient-15)"/>
                <rect id="Rectangle_4667" data-name="Rectangle 4667" width="6.075" height="10.216" transform="translate(44.797 60.282)" fill="#7bb4f1"/>
                <rect id="Rectangle_4668" data-name="Rectangle 4668" width="6.075" height="10.216" transform="translate(44.797 42.981)" fill="#7bb4f1"/>
                <rect id="Rectangle_4669" data-name="Rectangle 4669" width="6.075" height="10.217" transform="translate(44.304 235.49)" fill="#7bb4f1"/>
                <rect id="Rectangle_4670" data-name="Rectangle 4670" width="6.075" height="10.217" transform="translate(44.304 218.189)" fill="#7bb4f1"/>
                <rect id="Rectangle_4671" data-name="Rectangle 4671" width="6.075" height="10.208" transform="translate(33.923 114.037)" fill="#7bb4f1"/>
                <rect id="Rectangle_4672" data-name="Rectangle 4672" width="6.075" height="10.207" transform="translate(33.923 96.736)" fill="url(#linear-gradient-14)"/>
                <rect id="Rectangle_4673" data-name="Rectangle 4673" width="6.076" height="10.208" transform="translate(33.429 289.245)" fill="url(#linear-gradient-14)"/>
                <rect id="Rectangle_4674" data-name="Rectangle 4674" width="6.076" height="10.207" transform="translate(33.429 271.944)" fill="#7bb4f1"/>
                <rect id="Rectangle_4675" data-name="Rectangle 4675" width="6.075" height="10.207" transform="translate(33.208 26.9)" fill="url(#linear-gradient-14)"/>
                <rect id="Rectangle_4676" data-name="Rectangle 4676" width="5.594" height="10.208" transform="translate(30.575 9.599)" fill="url(#linear-gradient-19)"/>
                <rect id="Rectangle_4677" data-name="Rectangle 4677" width="6.075" height="10.208" transform="translate(33.038 147.428)" fill="#7bb4f1"/>
                <rect id="Rectangle_4678" data-name="Rectangle 4678" width="6.075" height="10.208" transform="translate(33.038 130.126)" fill="#7bb4f1"/>
                <rect id="Rectangle_4679" data-name="Rectangle 4679" width="6.076" height="10.216" transform="translate(32.714 202.099)" fill="url(#linear-gradient-20)"/>
                <rect id="Rectangle_4680" data-name="Rectangle 4680" width="6.076" height="10.216" transform="translate(32.714 184.798)" fill="url(#linear-gradient-20)"/>
                <path id="Path_442" data-name="Path 442" d="M766.84,563.486v10.2l-6.075-1.877v-8.322Z" transform="translate(-728.22 -258.161)" fill="url(#linear-gradient-22)"/>
                <rect id="Rectangle_4681" data-name="Rectangle 4681" width="6.075" height="10.216" transform="translate(32.323 60.282)" fill="url(#linear-gradient-15)"/>
                <rect id="Rectangle_4682" data-name="Rectangle 4682" width="6.075" height="10.216" transform="translate(32.323 42.981)" fill="url(#linear-gradient-15)"/>
                <rect id="Rectangle_4683" data-name="Rectangle 4683" width="6.075" height="10.217" transform="translate(31.829 235.49)" fill="url(#linear-gradient-15)"/>
                <rect id="Rectangle_4684" data-name="Rectangle 4684" width="6.075" height="10.217" transform="translate(31.829 218.189)" fill="url(#linear-gradient-15)"/>
                <rect id="Rectangle_4685" data-name="Rectangle 4685" width="6.075" height="10.208" transform="translate(22.155 114.037)" fill="#7bb4f1"/>
                <rect id="Rectangle_4686" data-name="Rectangle 4686" width="6.075" height="10.207" transform="translate(22.155 96.736)" fill="url(#linear-gradient-14)"/>
                <rect id="Rectangle_4687" data-name="Rectangle 4687" width="6.075" height="10.208" transform="translate(21.661 289.245)" fill="#7bb4f1"/>
                <rect id="Rectangle_4688" data-name="Rectangle 4688" width="6.075" height="10.207" transform="translate(21.661 271.944)" fill="url(#linear-gradient-14)"/>
                <rect id="Rectangle_4689" data-name="Rectangle 4689" width="6.066" height="10.207" transform="translate(21.44 26.9)" fill="url(#linear-gradient-29)"/>
                <rect id="Rectangle_4690" data-name="Rectangle 4690" width="5.586" height="10.208" transform="translate(19.74 9.599)" fill="#7bb4f1"/>
                <rect id="Rectangle_4691" data-name="Rectangle 4691" width="6.075" height="10.208" transform="translate(21.269 147.428)" fill="url(#linear-gradient-13)"/>
                <rect id="Rectangle_4692" data-name="Rectangle 4692" width="6.075" height="10.208" transform="translate(21.269 130.126)" fill="url(#linear-gradient-31)"/>
                <rect id="Rectangle_4693" data-name="Rectangle 4693" width="6.075" height="10.216" transform="translate(20.946 202.099)" fill="url(#linear-gradient-15)"/>
                <rect id="Rectangle_4694" data-name="Rectangle 4694" width="6.075" height="10.216" transform="translate(20.946 184.798)" fill="#7bb4f1"/>
                <path id="Path_443" data-name="Path 443" d="M755.072,570.053v3.65H749v-5.536Z" transform="translate(-728.22 -258.161)" fill="#7bb4f1"/>
                <path id="Path_444" data-name="Path 444" d="M755.072,563.486v6.566L749,568.167v-4.681Z" transform="translate(-728.22 -258.161)" fill="#7bb4f1"/>
                <rect id="Rectangle_4695" data-name="Rectangle 4695" width="6.066" height="10.216" transform="translate(20.556 60.282)" fill="url(#linear-gradient-33)"/>
                <rect id="Rectangle_4696" data-name="Rectangle 4696" width="6.066" height="10.216" transform="translate(20.556 42.981)" fill="url(#linear-gradient-33)"/>
                <rect id="Rectangle_4697" data-name="Rectangle 4697" width="6.075" height="10.217" transform="translate(20.061 235.49)" fill="url(#linear-gradient-15)"/>
                <rect id="Rectangle_4698" data-name="Rectangle 4698" width="6.075" height="10.217" transform="translate(20.061 218.189)" fill="url(#linear-gradient-15)"/>
                <rect id="Rectangle_4699" data-name="Rectangle 4699" width="6.075" height="10.208" transform="translate(9.681 114.037)" fill="#7bb4f1"/>
                <rect id="Rectangle_4700" data-name="Rectangle 4700" width="6.075" height="10.207" transform="translate(9.681 96.736)" fill="#7bb4f1"/>
                <rect id="Rectangle_4701" data-name="Rectangle 4701" width="6.075" height="10.208" transform="translate(9.188 289.245)" fill="#7bb4f1"/>
                <rect id="Rectangle_4702" data-name="Rectangle 4702" width="6.075" height="10.207" transform="translate(9.188 271.944)" fill="#7bb4f1"/>
                <rect id="Rectangle_4703" data-name="Rectangle 4703" width="6.067" height="10.207" transform="translate(8.966 26.9)" fill="#7bb4f1"/>
                <rect id="Rectangle_4704" data-name="Rectangle 4704" width="5.586" height="10.208" transform="translate(8.255 9.599)" fill="#7bb4f1"/>
                <rect id="Rectangle_4705" data-name="Rectangle 4705" width="6.075" height="10.208" transform="translate(8.796 147.428)" fill="#7bb4f1"/>
                <rect id="Rectangle_4706" data-name="Rectangle 4706" width="6.075" height="10.208" transform="translate(8.796 130.126)" fill="#7bb4f1"/>
                <rect id="Rectangle_4707" data-name="Rectangle 4707" width="6.075" height="10.216" transform="translate(8.473 202.099)" fill="url(#linear-gradient-15)"/>
                <rect id="Rectangle_4708" data-name="Rectangle 4708" width="6.075" height="10.216" transform="translate(8.473 184.798)" fill="#7bb4f1"/>
                <path id="Path_445" data-name="Path 445" d="M742.6,566.185V573.7h-6.075v-9.395Z" transform="translate(-728.22 -258.161)" fill="#7bb4f1"/>
                <path id="Path_446" data-name="Path 446" d="M742.6,563.486v2.7l-6.075-1.877v-.822Z" transform="translate(-728.22 -258.161)" fill="#7bb4f1"/>
                <rect id="Rectangle_4709" data-name="Rectangle 4709" width="6.066" height="10.216" transform="translate(8.081 60.282)" fill="#7bb4f1"/>
                <rect id="Rectangle_4710" data-name="Rectangle 4710" width="6.066" height="10.216" transform="translate(8.081 42.981)" fill="#7bb4f1"/>
                <rect id="Rectangle_4711" data-name="Rectangle 4711" width="6.075" height="10.217" transform="translate(7.588 235.49)" fill="#7bb4f1"/>
                <rect id="Rectangle_4712" data-name="Rectangle 4712" width="6.075" height="10.217" transform="translate(7.588 218.189)" fill="#7bb4f1"/>
                <path id="Path_447" data-name="Path 447" d="M877.6,337.276v6.765l-68.186-.078V337.2Z" transform="translate(-728.22 -258.161)" fill="#7bb4f1"/>
                <path id="Path_448" data-name="Path 448" d="M809.648,584.059v-64.81h71.639l.219,64.81Z" transform="translate(-728.22 -258.161)" fill="url(#linear-gradient-4)"/>
                <path id="Path_449" data-name="Path 449" d="M881.287,431.065v88.184h-.455v-6.765H809.648v-81.5Z" transform="translate(-728.22 -258.161)" fill="url(#linear-gradient-4)"/>
                <path id="Path_450" data-name="Path 450" d="M877.6,424.3v6.765l-68.186-.078v-6.765Z" transform="translate(-728.22 -258.161)" fill="#7bb4f1"/>
                <path id="Path_451" data-name="Path 451" d="M881.287,344.04V424.3l-71.639-.078v-80.26Z" transform="translate(-728.22 -258.161)" fill="url(#linear-gradient-4)"/>
                <path id="Path_452" data-name="Path 452" d="M809.648,258.161V337.2l71.639.078.225-79.115Z" transform="translate(-728.22 -258.161)" fill="url(#linear-gradient-4)"/>
                <rect id="Rectangle_4713" data-name="Rectangle 4713" width="67.753" height="6.765" transform="translate(81.191 254.324)" fill="#7bb4f1"/>
                </g>
                <g id="Group_2999" data-name="Group 2999" transform="translate(157.21 281.888)">
                <path id="Path_453" data-name="Path 453" d="M745.549,426.651S702.7,424.612,688.94,453.36c-9.53,19.913-3.492,35.621-19.339,44.689C642.589,513.507,631.539,542,673.56,571h64.334s18.192-26.284,1.384-42.816-26.9-42.54,0-55.662C768.12,458.458,729.214,438.962,745.549,426.651Z" transform="translate(-645.625 -426.292)" fill="url(#linear-gradient-42)"/>
                <g id="Group_2996" data-name="Group 2996" transform="translate(16.559)">
                    <path id="Path_454" data-name="Path 454" d="M692.083,470.919c1.816,22.47-4.253,28.38-13.439,37.324a104.357,104.357,0,0,0-12.253,13.605c-14.812,20.165,14.227,49.088,14.522,49.378l.6-.446c-.291-.284-28.952-28.828-14.447-48.572a103.8,103.8,0,0,1,12.177-13.519c8.982-8.747,15.472-15.066,13.634-37.818-2.523-31.213,50.387-43.8,50.921-43.925l-.209-.654C743.05,426.417,689.515,439.149,692.083,470.919Z" transform="translate(-662.184 -426.292)" fill="url(#linear-gradient-4)"/>
                </g>
                <g id="Group_2997" data-name="Group 2997" transform="translate(38.217 0.381)">
                    <path id="Path_455" data-name="Path 455" d="M708.176,464.183c1.487,27.428-2.494,33.684-13.131,50.407-1.639,2.575-3.5,5.5-5.493,8.751-14.6,23.8,3.377,47.61,3.561,47.848l.666-.372c-.18-.234-17.9-23.724-3.52-47.164,1.993-3.248,3.849-6.167,5.488-8.742,10.714-16.842,14.723-23.143,13.225-50.761-1.688-31.181,35.067-36.756,35.438-36.808l-.131-.669C743.9,426.727,706.454,432.392,708.176,464.183Z" transform="translate(-683.843 -426.673)" fill="url(#linear-gradient-4)"/>
                </g>
                <g id="Group_2998" data-name="Group 2998" transform="translate(64.6 0.415)">
                    <path id="Path_456" data-name="Path 456" d="M731.1,450.377c3.331,12.525.175,15.87-6.112,22.534-1.4,1.48-2.98,3.157-4.668,5.123-9.486,11.051-9.041,26.536-7.882,37.224.3,2.767,1.45,5.508,2.779,8.681,3.937,9.393,9.328,22.257-4.995,46.915l.715.3c14.473-24.914,9.017-37.934,5.032-47.441-1.311-3.13-2.444-5.834-2.736-8.518-1.148-10.576-1.6-25.894,7.732-36.759,1.676-1.954,3.253-3.626,4.645-5.1,6.448-6.834,9.684-10.263,6.268-23.107-4-15.055,13.232-22.843,13.407-22.92l-.366-.6C744.742,426.785,726.964,434.807,731.1,450.377Z" transform="translate(-710.225 -426.707)" fill="url(#linear-gradient-4)"/>
                </g>
                </g>
                <g id="Group_3009" data-name="Group 3009" transform="translate(357.941 156.737)">
                <rect id="Rectangle_4714" data-name="Rectangle 4714" width="31.531" height="174.316" transform="translate(80.806 111.581)" fill="#c0d9fd"/>
                <path id="Path_457" data-name="Path 457" d="M927.162,512.523v74.516H846.356V512.523Z" transform="translate(-846.356 -301.142)" fill="#faf9fc"/>
                <rect id="Rectangle_4715" data-name="Rectangle 4715" width="79.699" height="3.988" transform="translate(1.107 207.347)" fill="url(#linear-gradient-8)"/>
                <rect id="Rectangle_4716" data-name="Rectangle 4716" width="79.699" height="1.627" transform="translate(1.107 207.347)" fill="url(#linear-gradient-4)"/>
                <path id="Path_458" data-name="Path 458" d="M927.162,438.988v69.5H846.356v-69.5h80.806Z" transform="translate(-846.356 -301.142)" fill="#faf9fc"/>
                <path id="Path_459" data-name="Path 459" d="M927.162,412.723v26.265H863.11V412.723Z" transform="translate(-846.356 -301.142)" fill="url(#linear-gradient-8)"/>
                <rect id="Rectangle_4717" data-name="Rectangle 4717" width="46.317" height="3.604" transform="translate(24.634 128.497)" fill="url(#linear-gradient-4)"/>
                <rect id="Rectangle_4718" data-name="Rectangle 4718" width="46.317" height="3.602" transform="translate(24.634 121.861)" fill="url(#linear-gradient-4)"/>
                <rect id="Rectangle_4719" data-name="Rectangle 4719" width="46.317" height="3.605" transform="translate(24.634 115.674)" fill="url(#linear-gradient-4)"/>
                <rect id="Rectangle_4720" data-name="Rectangle 4720" width="13.021" height="54.111" transform="translate(59.296 145.541)" fill="url(#linear-gradient-52)"/>
                <rect id="Rectangle_4721" data-name="Rectangle 4721" width="13.028" height="54.111" transform="translate(34.226 145.541)" fill="url(#linear-gradient-52)"/>
                <rect id="Rectangle_4722" data-name="Rectangle 4722" width="13.028" height="54.111" transform="translate(9.159 145.541)" fill="url(#linear-gradient-52)"/>
                <rect id="Rectangle_4723" data-name="Rectangle 4723" width="13.021" height="18.036" transform="translate(59.297 145.541)" fill="#c0d9fd"/>
                <rect id="Rectangle_4724" data-name="Rectangle 4724" width="13.028" height="18.036" transform="translate(34.227 145.541)" fill="#c0d9fd"/>
                <rect id="Rectangle_4725" data-name="Rectangle 4725" width="13.028" height="18.036" transform="translate(9.16 145.541)" fill="#c0d9fd"/>
                <rect id="Rectangle_4726" data-name="Rectangle 4726" width="13.021" height="54.11" transform="translate(59.296 220.095)" fill="url(#linear-gradient-52)"/>
                <rect id="Rectangle_4727" data-name="Rectangle 4727" width="13.028" height="54.11" transform="translate(34.226 220.095)" fill="url(#linear-gradient-52)"/>
                <rect id="Rectangle_4728" data-name="Rectangle 4728" width="13.028" height="54.11" transform="translate(9.159 220.095)" fill="url(#linear-gradient-52)"/>
                <rect id="Rectangle_4729" data-name="Rectangle 4729" width="13.021" height="18.571" transform="translate(59.297 220.095)" fill="#c0d9fd"/>
                <rect id="Rectangle_4730" data-name="Rectangle 4730" width="13.028" height="18.571" transform="translate(34.227 220.095)" fill="#c0d9fd"/>
                <rect id="Rectangle_4731" data-name="Rectangle 4731" width="13.028" height="18.571" transform="translate(9.16 220.095)" fill="#c0d9fd"/>
                <g id="Group_3000" data-name="Group 3000" transform="translate(73.839 205.855)">
                    <path id="Path_460" data-name="Path 460" d="M920.2,508.479c.005-.815,1.18-1.485,2.613-1.482s2.6.677,2.6,1.491Z" transform="translate(-920.195 -506.997)" fill="url(#linear-gradient-58)"/>
                </g>
                <g id="Group_3001" data-name="Group 3001" transform="translate(3.57 205.768)">
                    <path id="Path_461" data-name="Path 461" d="M849.926,508.391c.005-.814,1.18-1.484,2.614-1.481s2.6.677,2.594,1.49Z" transform="translate(-849.926 -506.91)" fill="url(#linear-gradient-59)"/>
                </g>
                <g id="Group_3002" data-name="Group 3002" transform="translate(25.319 205.809)">
                    <path id="Path_462" data-name="Path 462" d="M871.675,508.433c.005-.815,1.18-1.484,2.614-1.482s2.6.678,2.594,1.491Z" transform="translate(-871.675 -506.951)" fill="url(#linear-gradient-60)"/>
                </g>
                <g id="Group_3003" data-name="Group 3003" transform="translate(49.16 205.855)">
                    <path id="Path_463" data-name="Path 463" d="M895.516,508.479c.005-.815,1.18-1.484,2.614-1.482s2.6.677,2.594,1.491Z" transform="translate(-895.516 -506.997)" fill="url(#linear-gradient-61)"/>
                </g>
                <g id="Group_3004" data-name="Group 3004" transform="translate(72.472 136.075)">
                    <path id="Path_464" data-name="Path 464" d="M918.828,438.7c0-.814,1.179-1.483,2.612-1.48s2.6.677,2.6,1.49Z" transform="translate(-918.828 -437.217)" fill="url(#linear-gradient-62)"/>
                </g>
                <g id="Group_3005" data-name="Group 3005" transform="translate(2.202 136.363)">
                    <path id="Path_465" data-name="Path 465" d="M848.558,438.985c.005-.813,1.181-1.483,2.614-1.48s2.6.677,2.6,1.491Z" transform="translate(-848.558 -437.505)" fill="url(#linear-gradient-63)"/>
                </g>
                <g id="Group_3006" data-name="Group 3006" transform="translate(23.952 136.405)">
                    <path id="Path_466" data-name="Path 466" d="M870.308,439.027c0-.814,1.18-1.483,2.613-1.48s2.6.677,2.595,1.49Z" transform="translate(-870.308 -437.547)" fill="url(#linear-gradient-64)"/>
                </g>
                <g id="Group_3007" data-name="Group 3007" transform="translate(47.792 136.451)">
                    <path id="Path_467" data-name="Path 467" d="M894.148,439.073c.005-.814,1.181-1.483,2.613-1.48s2.6.676,2.6,1.49Z" transform="translate(-894.148 -437.593)" fill="url(#linear-gradient-65)"/>
                </g>
                <path id="Path_468" data-name="Path 468" d="M846.356,439.028l81.467-.156.01,1.646-81.467.156Z" transform="translate(-846.356 -301.142)" fill="none"/>
                <rect id="Rectangle_4732" data-name="Rectangle 4732" width="28.334" height="5.87" transform="translate(77.107 105.624) rotate(-0.2)" fill="url(#linear-gradient-66)"/>
                <rect id="Rectangle_4733" data-name="Rectangle 4733" width="51.378" height="5.87" transform="translate(28.365 105.701) rotate(-0.2)" fill="#fff"/>
                <g id="Group_3008" data-name="Group 3008" transform="translate(33.972)">
                    <path id="Path_469" data-name="Path 469" d="M880.328,406.718v.064l.7,0,1.928-10.382H882.5a.341.341,0,0,1-.243-.1Z" transform="translate(-880.328 -301.142)" fill="#fff"/>
                    <path id="Path_470" data-name="Path 470" d="M882.151,396.051a.335.335,0,0,0,.1.247l.109-.56A.355.355,0,0,0,882.151,396.051Z" transform="translate(-880.328 -301.142)" fill="#fff"/>
                    <path id="Path_471" data-name="Path 471" d="M882.365,395.738l-.109.56a.341.341,0,0,0,.243.1h.458l.129-.688h-.59A.31.31,0,0,0,882.365,395.738Z" transform="translate(-880.328 -301.142)" fill="#fff"/>
                    <path id="Path_472" data-name="Path 472" d="M883.128,391.611l-.763,4.127a.31.31,0,0,1,.131-.028h.59l.7-3.752h-.3a.329.329,0,0,1-.318-.219.284.284,0,0,1-.03-.128Z" transform="translate(-880.328 -301.142)" fill="#fff"/>
                    <path id="Path_473" data-name="Path 473" d="M883.086,395.708l-.129.688,35.137-.115-.134-.687Z" transform="translate(-880.328 -301.142)" fill="#fff"/>
                    <path id="Path_474" data-name="Path 474" d="M887.938,365.681l-4.81,25.93h.008a.325.325,0,0,1,.112-.248l.764-.69,4.518-24.316-.469-.411a.359.359,0,0,1-.113-.265Z" transform="translate(-880.328 -301.142)" fill="#fff"/>
                    <path id="Path_475" data-name="Path 475" d="M883.136,391.611a.284.284,0,0,0,.03.128.329.329,0,0,0,.318.219h.3l.229-1.284-.764.69A.325.325,0,0,0,883.136,391.611Z" transform="translate(-880.328 -301.142)" fill="#fff"/>
                    <path id="Path_476" data-name="Path 476" d="M899.933,376.431l-11.4-10.074.128-.724.131.027,11.666,10.3,11.6-10.386.122-.019.133.723-11.334,10.148L917,390.565l.237,1.283-33.453.109.229-1.284Zm.526.466L884.4,391.268l32.219-.106Z" transform="translate(-880.328 -301.142)" fill="#fff"/>
                    <path id="Path_477" data-name="Path 477" d="M892.3,342.126l-4.361,23.555h.01a.339.339,0,0,1,.083-.221l.829-.919,4.013-21.628-.48-.567a.335.335,0,0,1-.085-.22Z" transform="translate(-880.328 -301.142)" fill="#fff"/>
                    <path id="Path_478" data-name="Path 478" d="M888.031,365.46a.339.339,0,0,0-.083.221.359.359,0,0,0,.113.265l.469.411.128-.724.2-1.092Z" transform="translate(-880.328 -301.142)" fill="#fff"/>
                    <path id="Path_479" data-name="Path 479" d="M888.86,364.541l-.2,1.092.131.027,12.072-13.313,11.2,13.228.122-.019-.229-1.191-10.618-12.542,6.763-7.465-.143-.76-.112.018-6.967,7.677-7.745-9.151-.112-.018-.146.789,7.538,8.895Z" transform="translate(-880.328 -301.142)" fill="#fff"/>
                    <path id="Path_480" data-name="Path 480" d="M894.64,329.524l-2.341,12.6h.009a.364.364,0,0,1,.1-.247l.792-.782,2.011-10.832-.479-.513a.34.34,0,0,1-.085-.228Z" transform="translate(-880.328 -301.142)" fill="#fff"/>
                    <path id="Path_481" data-name="Path 481" d="M892.308,342.126a.335.335,0,0,0,.085.22l.48.567.146-.789.184-1.027-.792.782A.364.364,0,0,0,892.308,342.126Z" transform="translate(-880.328 -301.142)" fill="#fff"/>
                    <path id="Path_482" data-name="Path 482" d="M899.577,334.879l-4.363-4.614.2-1.092,9.76-.032.2,1.044-4.819,4.71,7.189,7.6.209,1.1-.113.019-7.788-8.234-6.924,6.76-.112-.018.184-1.027Zm5.162-5.049-8.939.029,4.28,4.533Z" transform="translate(-880.328 -301.142)" fill="#fff"/>
                    <path id="Path_483" data-name="Path 483" d="M895.944,322.47l-1.3,7.054h.01a.376.376,0,0,1,.027-.138.347.347,0,0,1,.318-.212h.421l1.184-6.366-.29,0a.35.35,0,0,1-.356-.339Z" transform="translate(-880.328 -301.142)" fill="#fff"/>
                    <path id="Path_484" data-name="Path 484" d="M894.677,329.386a.376.376,0,0,0-.027.138.34.34,0,0,0,.085.228l.479.513.2-1.092h-.421A.347.347,0,0,0,894.677,329.386Z" transform="translate(-880.328 -301.142)" fill="#fff"/>
                    <path id="Path_485" data-name="Path 485" d="M899.855,301.383l-3.911,21.087h.01a.351.351,0,0,1,.353-.349h.421l3.481-18.785,3.607,18.761h.42a.351.351,0,0,1,.356.347h.01l-4.054-21.061A.37.37,0,0,0,899.855,301.383Z" transform="translate(-880.328 -301.142)" fill="#fff"/>
                    <path id="Path_486" data-name="Path 486" d="M896.307,322.121a.344.344,0,1,0,0,.688l.29,0,.128-.687Z" transform="translate(-880.328 -301.142)" fill="#fff"/>
                    <path id="Path_487" data-name="Path 487" d="M896.728,322.12l-.128.688,7.349-.024-.133-.687Z" transform="translate(-880.328 -301.142)" fill="#fff"/>
                    <path id="Path_488" data-name="Path 488" d="M903.816,322.1l.133.687h.289a.344.344,0,1,0,0-.687Z" transform="translate(-880.328 -301.142)" fill="#fff"/>
                    <path id="Path_489" data-name="Path 489" d="M903.949,322.783l1.227,6.358h.421a.343.343,0,0,1,.319.218h.009l-1.323-6.916h-.01a.348.348,0,0,1-.354.34Z" transform="translate(-880.328 -301.142)" fill="#fff"/>
                    <path id="Path_490" data-name="Path 490" d="M905.176,329.141l.2,1.044.465-.459a.319.319,0,0,0,.074-.368.343.343,0,0,0-.319-.218Z" transform="translate(-880.328 -301.142)" fill="#fff"/>
                    <path id="Path_491" data-name="Path 491" d="M905.377,330.185l2.37,12.313.825.877a.759.759,0,0,1,.075.109l-2.722-14.126h-.009a.319.319,0,0,1-.074.368Z" transform="translate(-880.328 -301.142)" fill="#fff"/>
                    <path id="Path_492" data-name="Path 492" d="M907.747,342.5l.209,1.1.142.76.476-.523a.32.32,0,0,0,.093-.23.442.442,0,0,0-.02-.12.759.759,0,0,0-.075-.109Z" transform="translate(-880.328 -301.142)" fill="#fff"/>
                    <path id="Path_493" data-name="Path 493" d="M908.574,343.834l-.476.523,3.856,20.008.863,1.024a.237.237,0,0,1,.057.092L908.667,343.6A.32.32,0,0,1,908.574,343.834Z" transform="translate(-880.328 -301.142)" fill="#fff"/>
                    <path id="Path_494" data-name="Path 494" d="M911.954,364.365l.228,1.191.133.723.466-.414a.368.368,0,0,0,.121-.257.324.324,0,0,0-.028-.127.237.237,0,0,0-.057-.092Z" transform="translate(-880.328 -301.142)" fill="#fff"/>
                    <path id="Path_495" data-name="Path 495" d="M912.315,366.279,917,390.565l.768.685a.424.424,0,0,1,.084.11L912.9,365.608a.368.368,0,0,1-.121.257Z" transform="translate(-880.328 -301.142)" fill="#fff"/>
                    <path id="Path_496" data-name="Path 496" d="M917.767,391.25l-.768-.685.237,1.283.29,0a.335.335,0,0,0,.327-.22.288.288,0,0,0,.028-.129.336.336,0,0,0-.03-.137A.424.424,0,0,0,917.767,391.25Z" transform="translate(-880.328 -301.142)" fill="#fff"/>
                    <path id="Path_497" data-name="Path 497" d="M917.236,391.848l.724,3.746h.421a.341.341,0,0,1,.346.338h.01l-.856-4.434a.288.288,0,0,1-.028.129.335.335,0,0,1-.327.22Z" transform="translate(-880.328 -301.142)" fill="#fff"/>
                    <path id="Path_498" data-name="Path 498" d="M917.96,395.594l.133.687h.289a.344.344,0,1,0,0-.688Z" transform="translate(-880.328 -301.142)" fill="#fff"/>
                    <path id="Path_499" data-name="Path 499" d="M918.093,396.281l2,10.37.7,0c0-.018-.009-.045-.009-.064l-2.047-10.654h-.01a.343.343,0,0,1-.345.35Z" transform="translate(-880.328 -301.142)" fill="#fff"/>
                </g>
                </g>
                <g id="Group_3017" data-name="Group 3017" transform="translate(438.778 261.256)">
                <path id="Path_500" data-name="Path 500" d="M941.746,491.184c-7.581-24.017-4.895-65.643,26.778-81.377s60.482,16.359,47.926,45.114-25.2,32.849-36.765,49.712,25.777,86.456-8.447,90.361c-19.372,2.211-48.939-40.36-43.355-67.1S947.5,509.42,941.746,491.184Z" transform="translate(-927.193 -405.66)" fill="url(#linear-gradient-67)"/>
                <g id="Group_3010" data-name="Group 3010" transform="translate(26.314 9.097)">
                    <path id="Path_501" data-name="Path 501" d="M967.3,526.405c1.311-15.1-1.31-21.738-4.628-30.136-.835-2.111-1.7-4.294-2.553-6.753-4.127-11.857-2.405-20.839,18.7-42.714,7.735-8.019,11.522-15.5,11.255-22.237a13.638,13.638,0,0,0-3.8-9.222l.539-.586a14.164,14.164,0,0,1,4.063,9.753c.287,6.963-3.574,14.646-11.474,22.833-20.824,21.581-22.547,30.374-18.529,41.918.85,2.444,1.711,4.62,2.542,6.722,3.356,8.495,6.007,15.2,4.68,30.49-.544,6.259-3.843,10.775-7.036,15.143-6.736,9.217-13.1,17.923,5.027,42.089l-.65.468c-18.472-24.627-11.639-33.977-5.031-43.018C963.537,536.876,966.772,532.449,967.3,526.405Z" transform="translate(-953.508 -414.757)" fill="#c0d9fd"/>
                </g>
                <g id="Group_3011" data-name="Group 3011" transform="translate(13.306 142.699)">
                    <path id="Path_502" data-name="Path 502" d="M953.965,558.793l-.089.783c-.1-.011-10.267-1.236-13.377-10.98l.768-.237C944.217,557.6,953.868,558.782,953.965,558.793Z" transform="translate(-940.499 -548.359)" fill="#c0d9fd"/>
                </g>
                <g id="Group_3012" data-name="Group 3012" transform="translate(23.234 109.033)">
                    <path id="Path_503" data-name="Path 503" d="M967.549,528.129l-.089.783c-.131-.013-13.072-1.574-17.033-13.982l.768-.237C955,526.6,967.424,528.114,967.549,528.129Z" transform="translate(-950.427 -514.693)" fill="#c0d9fd"/>
                </g>
                <g id="Group_3013" data-name="Group 3013" transform="translate(37.99 78.611)">
                    <path id="Path_504" data-name="Path 504" d="M977.953,484.271l.8.058c-.949,12.97-13.178,17.4-13.3,17.443l-.272-.742C965.3,500.987,977.043,496.719,977.953,484.271Z" transform="translate(-965.183 -484.271)" fill="#c0d9fd"/>
                </g>
                <g id="Group_3014" data-name="Group 3014" transform="translate(33.997 54.315)">
                    <path id="Path_505" data-name="Path 505" d="M989.333,459.975l.623.5a27.192,27.192,0,0,1-18.243,9.707,31.265,31.265,0,0,1-10.523-.527l.216-.761C961.572,468.941,978.222,473.285,989.333,459.975Z" transform="translate(-961.19 -459.975)" fill="#c0d9fd"/>
                </g>
                <g id="Group_3015" data-name="Group 3015" transform="translate(22.551 73.134)">
                    <path id="Path_506" data-name="Path 506" d="M962.526,493.553l-.249.752c-.112-.037-11.309-3.716-12.533-15.429l.8-.082C951.715,489.986,962.418,493.519,962.526,493.553Z" transform="translate(-949.744 -478.794)" fill="#c0d9fd"/>
                </g>
                <g id="Group_3016" data-name="Group 3016" transform="translate(31.481 32.889)">
                    <path id="Path_507" data-name="Path 507" d="M971.255,458.322l-.357.708c-.1-.05-10.579-5.282-12.224-20.4l.8-.085C961.07,453.214,971.153,458.273,971.255,458.322Z" transform="translate(-958.674 -438.549)" fill="#c0d9fd"/>
                </g>
                </g>
                <g id="Group_3019" data-name="Group 3019" transform="translate(484.388 310.629)">
                <path id="Path_508" data-name="Path 508" d="M977.769,600.516c21.629-2.442,51.975-30.485,50.034-47.014-1.725-14.689-19.524,4.188-10.174-9.9,9.372-14.094,20.906-11.752,14.177-24.268-2.405-4.5-8.921-9.48-2.175-19.31,6.769-9.831-6.47-14.854-6.47-14.854s5-15.021-24.946-30.134A52.348,52.348,0,0,1,990.888,482c-8.146,13.887-1.38,33.037,5.634,44.819,0,0-11.046-7.649-15.065-6.767s6.918,17.285,3.105,29.605c-3.81,12.336-7.838,6.838-6.709,13.3s-9.456-.626-2.883,13.721C981.52,591.025,969.7,601.427,977.769,600.516Zm26.216-98.523c-5.1.969-9.619-.83-9.914-3.739a4.444,4.444,0,0,1,.96-3.068c-3.363-2.6,5.853-9.541,3.976-5.6-1.675,3.491,6.766,3.958,7.831,6.113.482.955,2.461,1.433,2.568,2.345C1009.634,499.986,1007.067,501.4,1003.985,501.993ZM991.958,531.75c.536-1.156,1.862,1.654,7.885,1.06,1.6-.147,1.711,2.544,3.183,2.446,3.355-.241,6.92-.456,6.641,1.9-.257,2.151-3.193,4.381-7.087,4.752C995.775,542.559,990.629,534.637,991.958,531.75Z" transform="translate(-972.803 -455.033)" fill="url(#linear-gradient-68)"/>
                <g id="Group_3018" data-name="Group 3018" transform="translate(14.861 3.574)">
                    <path id="Path_509" data-name="Path 509" d="M1011.849,485.165c2.575-14.66-10.677-26.441-10.812-26.558l-.982.41c.132.116,13.19,11.733,10.654,26.167-1.261,7.174,1.7,14.1,4.561,20.8,2.995,7.01,5.823,13.632,3.2,19.556-5.078,11.487-30.551,56.839-30.809,57.295l1.088.126c.255-.456,25.741-45.829,30.831-57.339,2.691-6.087-.17-12.783-3.2-19.873C1013.545,499.1,1010.607,492.229,1011.849,485.165Z" transform="translate(-987.664 -458.607)" fill="#c0d9fd"/>
                </g>
                </g>
                <g id="Group_3021" data-name="Group 3021" transform="translate(339.052 368.446)">
                <path id="Path_510" data-name="Path 510" d="M850.119,535.523c14.244-12.817,3.99-22.58,3.99-22.58-2.65,19.273-16.562,12.265-24.071,22.58s3.092,27.206,10.6,34.36c4.023,3.832,5.795,12.941,6.575,20.575H858.2c.873-6.8,1.278-15.337-.519-22.6C854.039,553.133,839.029,545.5,850.119,535.523Z" transform="translate(-827.468 -512.85)" fill="url(#linear-gradient-69)"/>
                <g id="Group_3020" data-name="Group 3020" transform="translate(7.456)">
                    <path id="Path_511" data-name="Path 511" d="M843.359,534.251c16.865-10.716,11.04-21.3,10.98-21.4l-.459.188c.058.1,5.684,10.376-10.828,20.866-4.909,3.118-7.539,6.332-8.042,9.825-.825,5.74,4.421,11.184,9.05,15.988a61.466,61.466,0,0,1,4.889,5.464c3.8,5.084,4.767,16.412,4.9,25.277h.54c-.115-8.9-1.094-20.278-5-25.509a62.091,62.091,0,0,0-4.925-5.506c-4.565-4.736-9.738-10.1-8.938-15.662C836,540.412,838.568,537.294,843.359,534.251Z" transform="translate(-834.924 -512.85)" fill="#7bb4f1"/>
                </g>
                </g>
                <g id="Group_3029" data-name="Group 3029" transform="translate(43.744 249.012)">
                <path id="Path_512" data-name="Path 512" d="M570.8,397.923c-31.335,16.314-33.123,57.583-25.023,81.182,6.15,17.917-8.062,10.054-13.07,36.659s25.481,68.056,44.806,65.416c34.143-4.664-4.707-72.686,6.5-89.648s23.758-21.311,35.7-50.069S602.135,381.608,570.8,397.923Z" transform="translate(-532.16 -393.417)" fill="#faf9fc"/>
                <g id="Group_3022" data-name="Group 3022" transform="translate(26.778 8.953)">
                    <path id="Path_513" data-name="Path 513" d="M589.218,402.37l-.559.644a13.392,13.392,0,0,1,3.985,9.005c.416,6.656-3.2,14.146-10.767,22.261-20.652,22.161-22.179,31.1-17.79,42.755.908,2.414,1.819,4.555,2.7,6.628,3.5,8.232,6.262,14.734,5.277,29.706-.394,5.987-3.531,10.442-6.565,14.748-6.42,9.114-13.058,18.539,5.963,42.507l.681-.52c-18.633-23.477-12.465-32.236-5.933-41.506,3.1-4.4,6.306-8.954,6.715-15.173,1-15.17-1.8-21.753-5.341-30.088-.877-2.063-1.784-4.2-2.688-6.6-4.262-11.32-2.729-20.054,17.619-41.891,7.736-8.3,11.433-16.009,10.99-22.91A13.978,13.978,0,0,0,589.218,402.37Z" transform="translate(-558.937 -402.37)" fill="#c0d9fd"/>
                </g>
                <g id="Group_3023" data-name="Group 3023" transform="translate(13.583 142.287)">
                    <path id="Path_514" data-name="Path 514" d="M546.56,535.7l-.817.273c3.328,9.594,13.537,10.571,13.64,10.581l.076-.844C559.361,545.7,549.7,544.766,546.56,535.7Z" transform="translate(-545.743 -535.704)" fill="#c0d9fd"/>
                </g>
                <g id="Group_3024" data-name="Group 3024" transform="translate(22.786 108.73)">
                    <path id="Path_515" data-name="Path 515" d="M555.763,502.147l-.817.272c4.236,12.211,17.23,13.456,17.361,13.468l.075-.843C572.256,515.033,559.814,513.824,555.763,502.147Z" transform="translate(-554.946 -502.147)" fill="#c0d9fd"/>
                </g>
                <g id="Group_3025" data-name="Group 3025" transform="translate(37.26 77.998)">
                    <path id="Path_516" data-name="Path 516" d="M569.42,488.273l.307.793c.123-.047,12.276-4.726,12.945-17.609l-.863-.042C581.17,483.734,569.538,488.231,569.42,488.273Z" transform="translate(-569.42 -471.415)" fill="#c0d9fd"/>
                </g>
                <g id="Group_3026" data-name="Group 3026" transform="translate(32.594 53.659)">
                    <path id="Path_517" data-name="Path 517" d="M564.963,456.549l-.209.82a31.557,31.557,0,0,0,10.544.276A27.364,27.364,0,0,0,593.349,447.6l-.676-.526C581.871,460.483,565.129,456.589,564.963,456.549Z" transform="translate(-564.754 -447.076)" fill="#c0d9fd"/>
                </g>
                <g id="Group_3027" data-name="Group 3027" transform="translate(21.321 73.214)">
                    <path id="Path_518" data-name="Path 518" d="M554.338,466.631l-.857.1c1.483,11.59,12.776,14.976,12.891,15.009l.244-.811C566.507,480.9,555.751,477.677,554.338,466.631Z" transform="translate(-553.481 -466.631)" fill="#c0d9fd"/>
                </g>
                <g id="Group_3028" data-name="Group 3028" transform="translate(29.385 33.165)">
                    <path id="Path_519" data-name="Path 519" d="M562.4,426.582l-.855.11c1.972,14.944,12.576,19.884,12.683,19.933l.361-.769C574.486,445.809,564.309,441.042,562.4,426.582Z" transform="translate(-561.545 -426.582)" fill="#c0d9fd"/>
                </g>
                </g>
                <g id="Group_3030" data-name="Group 3030" transform="translate(120.57 47.936)">
                <path id="Path_520" data-name="Path 520" d="M685.033,571.924h28.98l.312-86.367-28.979-.09Z" transform="translate(-608.985 -192.341)" fill="#7bb4f1"/>
                <path id="Path_521" data-name="Path 521" d="M686.352,206.457l-.6,167.223,28.98.089.655-181.661-29.306,14.348Z" transform="translate(-608.985 -192.108)" fill="#c0d9fd"/>
                <path id="Path_522" data-name="Path 522" d="M608.985,485.23l76.36.236.4-111.787-76.764-.238Z" transform="translate(-608.985 -192.266)" fill="#c0d9fd"/>
                <path id="Path_523" data-name="Path 523" d="M608.985,571.924h76.048l.313-86.457-76.36-.236Z" transform="translate(-608.985 -192.341)" fill="url(#linear-gradient-2)"/>
                <path id="Path_524" data-name="Path 524" d="M608.985,373.442l76.764.238.6-167.223-77.367-.239Z" transform="translate(-608.985 -192.108)" fill="url(#linear-gradient-3)"/>
                <rect id="Rectangle_4734" data-name="Rectangle 4734" width="111.788" height="28.98" transform="translate(76.389 293.201) rotate(-89.822)" fill="url(#linear-gradient-72)"/>
                <rect id="Rectangle_4735" data-name="Rectangle 4735" width="4.994" height="8.989" transform="matrix(0.003, -1, 1, 0.003, 59.273, 259.306)" fill="url(#linear-gradient-73)"/>
                <rect id="Rectangle_4736" data-name="Rectangle 4736" width="4.994" height="8.989" transform="translate(14.183 226.612) rotate(-89.823)" fill="url(#linear-gradient-74)"/>
                <rect id="Rectangle_4737" data-name="Rectangle 4737" width="4.993" height="8.99" transform="translate(40.571 202.624) rotate(-89.823)" fill="url(#linear-gradient-75)"/>
                <rect id="Rectangle_4738" data-name="Rectangle 4738" width="4.994" height="8.981" transform="matrix(0.003, -1, 1, 0.003, 27.222, 202.583)" fill="url(#linear-gradient-76)"/>
                <rect id="Rectangle_4739" data-name="Rectangle 4739" width="5.005" height="8.989" transform="translate(13.338 124.388) rotate(-89.826)" fill="url(#linear-gradient-77)"/>
                <rect id="Rectangle_4740" data-name="Rectangle 4740" width="4.994" height="8.99" transform="translate(26.203 148.485) rotate(-89.823)" fill="url(#linear-gradient-75)"/>
                <rect id="Rectangle_4741" data-name="Rectangle 4741" width="4.993" height="8.99" transform="translate(53.714 150.112) rotate(-89.822)" fill="url(#linear-gradient-79)"/>
                <rect id="Rectangle_4742" data-name="Rectangle 4742" width="4.994" height="8.989" transform="translate(40.761 150.072) rotate(-89.823)" fill="url(#linear-gradient-80)"/>
                <rect id="Rectangle_4743" data-name="Rectangle 4743" width="5.005" height="8.99" transform="matrix(0.003, -1, 1, 0.003, 49.249, 142.024)" fill="url(#linear-gradient-81)"/>
                <rect id="Rectangle_4744" data-name="Rectangle 4744" width="4.993" height="8.981" transform="translate(58.759 85.847) rotate(-89.823)" fill="url(#linear-gradient-82)"/>
                <rect id="Rectangle_4745" data-name="Rectangle 4745" width="38.736" height="4.099" transform="translate(94.618 340.521) rotate(-89.822)" fill="url(#linear-gradient-83)"/>
                <rect id="Rectangle_4746" data-name="Rectangle 4746" width="38.746" height="4.099" transform="translate(94.816 285.622) rotate(-89.822)" fill="#c0d9fd"/>
                <rect id="Rectangle_4747" data-name="Rectangle 4747" width="38.747" height="4.099" transform="translate(95.42 118.396) rotate(-89.822)" fill="url(#linear-gradient-84)"/>
                <rect id="Rectangle_4748" data-name="Rectangle 4748" width="38.747" height="4.09" transform="matrix(0.003, -1, 1, 0.003, 83.353, 227.929)" fill="#7bb4f1"/>
                <rect id="Rectangle_4749" data-name="Rectangle 4749" width="38.747" height="4.09" transform="translate(83.552 173.003) rotate(-89.822)" fill="#7bb4f1"/>
                <rect id="Rectangle_4750" data-name="Rectangle 4750" width="38.747" height="4.09" transform="translate(83.949 63.451) rotate(-89.829)" fill="#7bb4f1"/>
                <rect id="Rectangle_4751" data-name="Rectangle 4751" width="5.005" height="8.989" transform="matrix(0.003, -1, 1, 0.003, 61.582, 283.383)" fill="#fff"/>
                <rect id="Rectangle_4752" data-name="Rectangle 4752" width="5.005" height="8.981" transform="translate(58.672 109.916) rotate(-89.825)" fill="#fff"/>
                <rect id="Rectangle_4753" data-name="Rectangle 4753" width="5.005" height="8.989" transform="translate(46.242 283.335) rotate(-89.82)" fill="#fff"/>
                <rect id="Rectangle_4754" data-name="Rectangle 4754" width="5.005" height="8.99" transform="translate(46.3 267.175) rotate(-89.82)" fill="#fff"/>
                <rect id="Rectangle_4755" data-name="Rectangle 4755" width="4.993" height="8.99" transform="translate(45.806 85.807) rotate(-89.822)" fill="#fff"/>
                <rect id="Rectangle_4756" data-name="Rectangle 4756" width="5.005" height="8.989" transform="translate(38.684 132.376) rotate(-89.826)" fill="#fff"/>
                <rect id="Rectangle_4757" data-name="Rectangle 4757" width="5.005" height="8.989" transform="matrix(0.003, -1, 1, 0.003, 33.871, 267.137)" fill="#fff"/>
                <rect id="Rectangle_4758" data-name="Rectangle 4758" width="4.993" height="8.989" transform="translate(29.522 226.659) rotate(-89.823)" fill="#fff"/>
                <rect id="Rectangle_4759" data-name="Rectangle 4759" width="5.005" height="8.99" transform="translate(18.72 284.792) rotate(-89.826)" fill="#fff"/>
                <rect id="Rectangle_4760" data-name="Rectangle 4760" width="4.993" height="8.989" transform="translate(13.011 79.047) rotate(-89.823)" fill="url(#linear-gradient-75)"/>
                <rect id="Rectangle_4761" data-name="Rectangle 4761" width="5.005" height="8.989" transform="translate(10.703 37.059) rotate(-89.823)" fill="#fff"/>
                <rect id="Rectangle_4762" data-name="Rectangle 4762" width="5.005" height="8.989" transform="translate(26.042 37.106) rotate(-89.822)" fill="#fff"/>
                <rect id="Rectangle_4763" data-name="Rectangle 4763" width="5.005" height="8.99" transform="translate(25.983 53.276) rotate(-89.823)" fill="#fff"/>
                <rect id="Rectangle_4764" data-name="Rectangle 4764" width="5.005" height="8.989" transform="translate(38.414 53.315) rotate(-89.822)" fill="#fff"/>
                <rect id="Rectangle_4765" data-name="Rectangle 4765" width="5.005" height="8.989" transform="translate(53.564 35.649) rotate(-89.822)" fill="#fff"/>
                <rect id="Rectangle_4766" data-name="Rectangle 4766" width="4.993" height="8.99" transform="translate(18.793 194.482) rotate(-89.82)" fill="#fff"/>
                <rect id="Rectangle_4767" data-name="Rectangle 4767" width="4.994" height="8.989" transform="translate(14.27 202.543) rotate(-89.822)" fill="#fff"/>
                <rect id="Rectangle_4768" data-name="Rectangle 4768" width="4.994" height="8.989" transform="translate(55.339 355.85) rotate(-89.82)" fill="url(#linear-gradient-73)"/>
                <rect id="Rectangle_4769" data-name="Rectangle 4769" width="4.994" height="8.989" transform="matrix(0.003, -1, 1, 0.003, 10.248, 341.441)" fill="url(#linear-gradient-73)"/>
                <rect id="Rectangle_4770" data-name="Rectangle 4770" width="4.994" height="8.99" transform="translate(36.637 317.438) rotate(-89.82)" fill="url(#linear-gradient-73)"/>
                <rect id="Rectangle_4771" data-name="Rectangle 4771" width="4.993" height="8.981" transform="matrix(0.003, -1, 1, 0.003, 23.288, 317.397)" fill="url(#linear-gradient-89)"/>
                <rect id="Rectangle_4772" data-name="Rectangle 4772" width="5.005" height="8.99" transform="matrix(0.003, -1, 1, 0.003, 42.366, 363.723)" fill="#fff"/>
                <rect id="Rectangle_4773" data-name="Rectangle 4773" width="5.005" height="8.989" transform="matrix(0.003, -1, 1, 0.003, 29.936, 363.685)" fill="#fff"/>
                <rect id="Rectangle_4774" data-name="Rectangle 4774" width="4.994" height="8.989" transform="translate(25.588 341.489) rotate(-89.826)" fill="#fff"/>
                <rect id="Rectangle_4775" data-name="Rectangle 4775" width="4.994" height="8.99" transform="translate(14.858 309.292) rotate(-89.82)" fill="#fff"/>
                <rect id="Rectangle_4776" data-name="Rectangle 4776" width="4.993" height="8.989" transform="translate(10.335 317.357) rotate(-89.825)" fill="#fff"/>
                <rect id="Rectangle_4777" data-name="Rectangle 4777" width="4.993" height="8.989" transform="translate(10.855 148.438) rotate(-89.82)" fill="#fff"/>
                <path id="Path_525" data-name="Path 525" d="M608.985,205.974v.152l77.367.331,29-14.349H636.463Z" transform="translate(-608.985 -192.108)" fill="#faf9fc"/>
                </g>
                <g id="Group_3032" data-name="Group 3032" transform="translate(95.043 320.167)">
                <path id="Path_526" data-name="Path 526" d="M633.467,499.08s5.486-15.14-17.37-34.509a66.226,66.226,0,0,1-8.532,27.384c-7.924,13.581-4.267,34.436.3,47.609,0,0-8.229-9.314-11.58-8.869s3.962,18.924-.3,31.392-7.009,6.234-6.7,13.136-7.619-1.782-3.657,14.026-6.453,25.307,0,25.307c24.138,0,30.29-17.189,42.073-30.844s-13.225-10.419-4.286-24.073,18.086-9.833,13.815-23.749c-1.533-5-6.347-10.984.077-20.483S633.467,499.08,633.467,499.08Zm-15.913,52.869c-.418,2.234-3.007,4.213-6.205,4.143-5.588-.122-9-9.027-7.644-11.89.544-1.15,1.343,1.942,6.3,2.04,1.319.027,1.137,2.869,2.336,2.932C615.09,549.317,618.011,549.512,617.554,551.949ZM616.3,514.442c-4.244.41-7.728-2.013-7.7-5.08a5.492,5.492,0,0,1,1.08-3.1c-2.489-3.126,5.66-9.3,3.747-5.4-1.693,3.452,5.123,4.95,5.787,7.334.293,1.054,1.857,1.784,1.857,2.759C621.075,513.016,618.856,514.2,616.3,514.442Z" transform="translate(-583.459 -464.571)" fill="url(#linear-gradient-90)"/>
                <g id="Group_3031" data-name="Group 3031" transform="translate(10.649 3.95)">
                    <path id="Path_527" data-name="Path 527" d="M623.076,497.8c3.51-15.151-6.177-29.136-6.276-29.276l-.828.56c.1.137,9.534,13.764,6.122,28.5-1.718,7.419.029,15.054,1.718,22.437,1.754,7.659,3.41,14.893.752,20.719-5.214,11.428-30.2,55.919-30.456,56.367l.882.478c.252-.449,25.262-44.976,30.492-56.439,2.795-6.128,1.1-13.518-.687-21.342C623.13,512.52,621.407,505,623.076,497.8Z" transform="translate(-594.108 -468.521)" fill="#c0d9fd"/>
                </g>
                </g>
            </g>
            <g id="Group_3036" data-name="Group 3036" transform="translate(289.46 389.417)">
                <path id="Path_528" data-name="Path 528" d="M515.744,555.824c2.952-25.011-18.742-32.3-18.742-32.3s18.109,13.583,1.877,35.836,5.8,51.954,5.8,51.954h12.368S512.792,580.836,515.744,555.824Z" transform="translate(-452.096 -523.524)" fill="url(#linear-gradient-91)"/>
                <g id="Group_3035" data-name="Group 3035" transform="translate(40.815)">
                <path id="Path_529" data-name="Path 529" d="M511.042,611.234s-23.972-28.155-9.267-51.447c12.376-19.6.892-31.445-3.056-34.7,4.1,4.149,13.188,16.417.16,34.277-16.233,22.254,5.8,51.954,5.8,51.954h12.368s-.025-.175-.064-.478Z" transform="translate(-492.911 -523.524)" fill="#faf9fc"/>
                <g id="Group_3034" data-name="Group 3034" transform="translate(4.091)" opacity="0.44">
                    <path id="Path_530" data-name="Path 530" d="M497.876,524.269a27.613,27.613,0,0,1,3.722,1.5,26.6,26.6,0,0,0-4.6-2.248S497.341,523.782,497.876,524.269Z" transform="translate(-497.002 -523.524)" fill="#4900bc"/>
                </g>
                </g>
                <path id="Path_531" data-name="Path 531" d="M520.743,583.651c.575,11.021-.653,16.978-2.036,20.186,6.247-7.91,20.305-26.591,21.513-35.949,1.559-12.064,1.393-19.518,9.675-35.915,0,0-12.159,12.049-20.788,19.379S519.584,561.456,520.743,583.651Z" transform="translate(-452.096 -523.524)" fill="url(#linear-gradient-92)"/>
                <path id="Path_532" data-name="Path 532" d="M508.884,607.517s-1.158-1.394-2.962-3.68C507.323,607.09,508.884,607.517,508.884,607.517Z" transform="translate(-452.096 -523.524)" fill="url(#linear-gradient-93)"/>
                <path id="Path_533" data-name="Path 533" d="M511.356,582.823c-1.577.607-2.993,1.209-4.266,1.8a6.545,6.545,0,0,1,.55.915c2.992,6.164,3.45,12.26,3.418,18.615h9.03s9.158-12.39,4.851-30.831S528.031,576.419,511.356,582.823Z" transform="translate(-452.096 -523.524)" fill="url(#linear-gradient-94)"/>
                <path id="Path_534" data-name="Path 534" d="M501.529,577.965c-9.166-4.638-16.123,7.126-16.123,7.126s3.865-2.709,9.718,4.358,4.306,16.122,4.306,16.122l8.614-3.091S510.694,582.6,501.529,577.965Z" transform="translate(-452.096 -523.524)" fill="url(#linear-gradient-94)"/>
                <path id="Path_535" data-name="Path 535" d="M547.972,570.687c-4.779-3.268-12.2,1.113-12.8,3.739-1.9,8.3-16.75,28.495-16.75,28.495s7.178-1.215,15.792-9.718,6.52-19.574,14.767-12.719,10.68,13.12,10.68,13.12S560.675,579.372,547.972,570.687Z" transform="translate(-452.096 -523.524)" fill="url(#linear-gradient-94)"/>
                <path id="Path_536" data-name="Path 536" d="M452.173,581.022a1.416,1.416,0,0,0-.077.255S452.12,581.185,452.173,581.022Z" transform="translate(-452.096 -523.524)" fill="#faf9fc"/>
                <path id="Path_537" data-name="Path 537" d="M530.975,577.314c-2.871,3.865-12.662,18.1-13.766,24.723s3.644-1.325,3.644-1.325,6.847-11.373,15.238-17.446,1.5-17.9,15.5-5.036c8.577,7.877,8.6,18.62,8.174,19.723.725-1.643,4.419-11.67-6.708-24.341C540.878,559.739,533.846,573.449,530.975,577.314Z" transform="translate(-452.096 -523.524)" fill="url(#linear-gradient-97)"/>
                <path id="Path_538" data-name="Path 538" d="M501.418,574.541c-6.747-3.743-16.895-4.858-25.287,12.258s-13.362,17.875-13.362,17.875a49.824,49.824,0,0,0,28.269-17.213c11.707-14.589,15.32-4.085,16.052,3.313s.954,11.706.954,11.706l1.377-.443S510.992,579.855,501.418,574.541Z" transform="translate(-452.096 -523.524)" fill="url(#linear-gradient-98)"/>
                <path id="Path_539" data-name="Path 539" d="M513.667,564.02a8.831,8.831,0,0,1-7.964.729,7.2,7.2,0,0,0-7.381.742c-5.406,3.976-.543,16.338-2.425,23.958,0,0,13.887-6.016,15.464-6.626,8.852-3.4,10.919-11.064,11.555-14.748,0-.026,0-.054.013-.066l.239-1.989S522.492,558.68,513.667,564.02Z" transform="translate(-452.096 -523.524)" fill="url(#linear-gradient-99)"/>
            </g>
            <g id="Group_3039" data-name="Group 3039" transform="translate(199.391 381.08)">
                <path id="Path_540" data-name="Path 540" d="M409.82,515.187s26.514,14.338,9.5,46.83S437.135,629.3,437.135,629.3l16.288-2.424s-11.572-39.308-12.587-72.828S409.82,515.187,409.82,515.187Z" transform="translate(-361.997 -515.187)" fill="url(#linear-gradient-91)"/>
                <g id="Group_3038" data-name="Group 3038" transform="translate(47.823)">
                <path id="Path_541" data-name="Path 541" d="M445.5,627.953s-37.091-32.384-22.289-65.942c12.457-28.245-4.989-41.591-10.827-45.11,6.211,4.663,20.586,19.038,6.928,45.116C402.3,594.507,437.135,629.3,437.135,629.3l16.288-2.424s-.066-.227-.178-.617Z" transform="translate(-409.82 -515.187)" fill="url(#linear-gradient)"/>
                <g id="Group_3037" data-name="Group 3037" opacity="0.44">
                    <path id="Path_542" data-name="Path 542" d="M411.118,516a36.7,36.7,0,0,1,5.2,1.25,35.2,35.2,0,0,0-6.495-2.059S410.318,515.459,411.118,516Z" transform="translate(-409.82 -515.187)" fill="#4900bc"/>
                </g>
                </g>
                <path id="Path_543" data-name="Path 543" d="M457.56,545.543c-9.927,11.345-10.563,15.175-4.686,44.18,2.919,14.4,2.468,22.488,1.276,26.984,6.676-11.643,21.53-39,21.288-51.563-.312-16.2-1.992-25.979,5.7-49.2C481.14,515.944,467.487,534.2,457.56,545.543Z" transform="translate(-361.997 -515.187)" fill="url(#linear-gradient-102)"/>
                <path id="Path_544" data-name="Path 544" d="M441.934,623.479s-1.8-1.608-4.624-4.266C439.8,623.224,441.934,623.479,441.934,623.479Z" transform="translate(-361.997 -515.187)" fill="url(#linear-gradient-103)"/>
                <path id="Path_545" data-name="Path 545" d="M440.35,590.473c-1.959,1.106-3.705,2.177-5.265,3.212a8.951,8.951,0,0,1,.9,1.1c5.148,7.531,6.945,15.471,8.15,23.847l11.892-1.77s9.633-18.114.346-41.558S461.055,578.769,440.35,590.473Z" transform="translate(-361.997 -515.187)" fill="url(#linear-gradient-94)"/>
                <path id="Path_546" data-name="Path 546" d="M426.454,586c-12.981-4.311-19.838,12.547-19.838,12.547s4.56-4.327,13.653,3.834,8.833,20.389,8.833,20.389l10.738-5.759S439.434,590.311,426.454,586Z" transform="translate(-361.997 -515.187)" fill="url(#linear-gradient-94)"/>
                <path id="Path_547" data-name="Path 547" d="M486.2,567.311c-6.936-3.368-15.85,3.857-16.126,7.433-.873,11.3-16.473,40.813-16.473,40.813s9.215-3.007,18.893-15.894,4.751-27.058,16.956-19.646S506.084,595.2,506.084,595.2,504.63,576.26,486.2,567.311Z" transform="translate(-361.997 -515.187)" fill="url(#linear-gradient-94)"/>
                <path id="Path_548" data-name="Path 548" d="M362.048,599.7a1.917,1.917,0,0,0-.051.351S362.011,599.925,362.048,599.7Z" transform="translate(-361.997 -515.187)" fill="#faf9fc"/>
                <path id="Path_549" data-name="Path 549" d="M493.47,570.166c-18.764-15.884-25.338,3.551-28.361,9.2s-13.129,26.319-13.285,35.261,4.54-2.459,4.54-2.459,6.788-16.322,16.65-25.967-1.539-23.873,19.431-9.671c12.839,8.7,14.969,22.839,14.632,24.376C507.709,598.6,510.609,584.674,493.47,570.166Z" transform="translate(-361.997 -515.187)" fill="url(#linear-gradient-97)"/>
                <path id="Path_550" data-name="Path 550" d="M425.637,581.513c-9.62-3.609-23.2-3.088-30.9,21.1s-14.095,26.162-14.095,26.162a66.357,66.357,0,0,0,33.859-28.21c12.558-21.51,19.375-8.384,21.79,1.216s3.551,15.23,3.551,15.23l1.726-.852S439.288,586.633,425.637,581.513Z" transform="translate(-361.997 -515.187)" fill="url(#linear-gradient-108)"/>
                <path id="Path_551" data-name="Path 551" d="M439.707,565.254a11.766,11.766,0,0,1-10.347,2.521,9.589,9.589,0,0,0-9.575,2.424c-6.341,6.3,2.487,21.626,1.5,32.03,0,0,17.111-10.646,19.069-11.757,10.99-6.221,12.212-16.714,12.327-21.691a.13.13,0,0,1,0-.089l-.076-2.665S450.283,556.491,439.707,565.254Z" transform="translate(-361.997 -515.187)" fill="url(#linear-gradient-99)"/>
            </g>
            <path id="Path_552" data-name="Path 552" d="M162.525,661.107H985.206l-5.953-138.756Z" transform="translate(-162.525 -134.107)" fill="url(#linear-gradient-110)"/>
            <g id="Group_3040" data-name="Group 3040" transform="translate(235.279 392.742)">
                <path id="Path_553" data-name="Path 553" d="M419.729,661.107,967.576,531.9,398.1,661.107Z" transform="translate(-398.097 -526.85)" fill="#c0d9fd"/>
                <path id="Path_554" data-name="Path 554" d="M969.162,661.107l11.97-134.258L964.1,661.107Z" transform="translate(-398.097 -526.85)" fill="#c0d9fd"/>
                <path id="Path_555" data-name="Path 555" d="M869.9,661.107,981.131,526.85,863.309,661.107Z" transform="translate(-398.097 -526.85)" fill="#c0d9fd"/>
                <path id="Path_556" data-name="Path 556" d="M727.992,661.107,981.131,526.85l-264,134.258Z" transform="translate(-398.097 -526.85)" fill="#c0d9fd"/>
            </g>
            <path id="Path_557" data-name="Path 557" d="M582.206,600.113c-62.338,7.567-121.265,26.323-131.618,41.893s31.788,22.058,94.127,14.49S665.979,630.175,676.333,614.6,644.544,592.547,582.206,600.113Z" transform="translate(-162.656 -134.107)" fill="url(#radial-gradient)" style="mix-blend-mode: multiply;isolation: isolate"/>
            <path id="Path_558" data-name="Path 558" d="M785.95,566.447c-30.762,4.748-59.655,14.985-64.534,22.865s16.1,10.418,46.863,5.669S827.934,580,832.814,572.117,816.712,561.7,785.95,566.447Z" transform="translate(-162.741 -134.107)" fill="url(#radial-gradient-2)" style="mix-blend-mode: multiply;isolation: isolate"/>
            <g id="Group_3041" data-name="Group 3041" transform="translate(604.23 180.496)">
                <path id="Path_559" data-name="Path 559" d="M957.236,542.837a10.859,10.859,0,0,0,21.719,0V411.2H957.236Z" transform="translate(-767.049 -314.516)" fill="url(#linear-gradient-111)"/>
                <path id="Path_560" data-name="Path 560" d="M970.733,357.338l-23.384,3.8,10.061,61.966,23.421-3.808c14.646-2.381,24.256-18.191,21.46-35.306S985.37,354.957,970.733,357.338Z" transform="translate(-767.049 -314.516)" fill="url(#linear-gradient-112)"/>
                <path id="Path_561" data-name="Path 561" d="M983.966,380.3a54.8,54.8,0,0,0-11.049-18.87c-8.79-10.08-25.83-15.69-46.031-20.77-.429-.11-.869-.22-1.309-.33-6.31-1.57-12.93-3.09-19.68-4.69a.168.168,0,0,0-.05-.02c-.2-.04-.41-.09-.61-.14-11.88-2.82-24.19-5.87-36.09-9.8-4.31-1.42-8.57-2.96-12.73-4.64-2.09-.85-4.17-1.73-6.21-2.66l.219,1.3.311,1.83L878.5,485.071l.729,4.341,1.481,8.7a265.489,265.489,0,0,1,37.89-31.1c7.15-4.91,13.93-9.16,19.85-12.68,3.22-1.92,6.189-3.62,8.83-5.11,2.87-1.62,5.35-2.97,7.33-4.05,1.24-.67,2.29-1.24,3.119-1.7,14.731-8.16,21.08-14.85,25.071-24.25a52.445,52.445,0,0,0,4.08-18.71,55.938,55.938,0,0,0-.571-10.27A57.033,57.033,0,0,0,983.966,380.3Z" transform="translate(-767.049 -314.516)" fill="url(#linear-gradient-113)"/>
                <path id="Path_562" data-name="Path 562" d="M961.5,378.972a75.563,75.563,0,0,0-16.12-25.57c-4.71-5.05-11-9.2-18.491-12.74-.429-.11-.869-.22-1.309-.33-6.31-1.57-12.93-3.09-19.68-4.69,13.4,4.56,24.39,10.21,31.659,17.99a75.518,75.518,0,0,1,16.12,25.57,77.994,77.994,0,0,1,3.661,13.62,77.143,77.143,0,0,1,1.21,14.15,72.229,72.229,0,0,1-4.911,25.969,53.013,53.013,0,0,1-15.189,21.391c3.22-1.92,6.189-3.62,8.83-5.11,2.87-1.62,5.35-2.97,7.33-4.05a52.173,52.173,0,0,0,6.85-12.46,72.457,72.457,0,0,0,4.92-25.97,80.286,80.286,0,0,0-4.88-27.77Z" transform="translate(-767.049 -314.516)" fill="url(#linear-gradient-114)" style="mix-blend-mode: multiply;isolation: isolate"/>
                <path id="Path_563" data-name="Path 563" d="M901.589,381.814c-4.968-16.1-12.59-30.167-21.883-41.231-15.671-18.6-36.092-28.713-56.768-25.466-38.2,5.906-61.612,61.829-54.676,109.894,6.807,48.024,45.368,94.523,83.509,88.245,33.32-5.485,46.777-40.6,49.239-47.414a123.746,123.746,0,0,0,7.009-40.236,143.059,143.059,0,0,0-6.43-43.792Z" transform="translate(-767.049 -314.516)" fill="url(#linear-gradient-115)"/>
                <path id="Path_564" data-name="Path 564" d="M902.321,385.346a104.629,104.629,0,0,0-19.3-36.359c-13.812-16.394-31.821-25.31-50.057-22.451-33.683,5.214-55.312,48.583-48.233,96.839,6.978,48.23,39.969,83.083,73.655,77.892,19.734-3.082,35.336-19.247,43.426-41.822a108.937,108.937,0,0,0,6.175-35.48,126.57,126.57,0,0,0-5.666-38.619Z" transform="translate(-767.049 -314.516)" fill="url(#linear-gradient-116)"/>
                <path id="Path_565" data-name="Path 565" d="M902.283,384.813a101.724,101.724,0,0,0-18.762-35.346c-13.43-15.938-30.935-24.605-48.665-21.826-32.744,5.069-53.771,47.23-46.889,94.143,6.784,46.886,38.856,80.769,71.605,75.722,19.184-3,34.351-18.709,42.217-40.656a105.948,105.948,0,0,0,6-34.493,123.091,123.091,0,0,0-5.508-37.544Z" transform="translate(-767.049 -314.516)" fill="url(#linear-gradient-117)"/>
                <path id="Path_566" data-name="Path 566" d="M902.91,388.381a81.749,81.749,0,0,0-15.077-28.4c-10.792-12.808-24.861-19.773-39.109-17.54-26.314,4.073-43.212,37.955-37.681,75.656,5.452,37.678,31.226,64.908,57.543,60.852,15.418-2.407,27.607-15.035,33.927-32.673a85.1,85.1,0,0,0,4.824-27.719,98.877,98.877,0,0,0-4.427-30.171Z" transform="translate(-767.049 -314.516)" fill="url(#linear-gradient-118)"/>
                <path id="Path_567" data-name="Path 567" d="M903.791,393.035a63.494,63.494,0,0,0-11.709-22.058c-8.38-9.946-19.3-15.355-30.369-13.621-20.434,3.163-33.556,29.474-29.261,58.75,4.233,29.26,24.248,50.4,44.684,47.254,11.972-1.869,21.438-11.675,26.346-25.371a66.078,66.078,0,0,0,3.746-21.525,76.766,76.766,0,0,0-3.437-23.429Z" transform="translate(-767.049 -314.516)" fill="url(#linear-gradient-119)"/>
                <path id="Path_568" data-name="Path 568" d="M903.794,392.226a60.045,60.045,0,0,0-11.074-20.861c-7.925-9.4-18.257-14.521-28.72-12.881-19.326,2.992-31.736,27.874-27.673,55.562,4,27.671,22.931,47.668,42.259,44.689,11.322-1.768,20.274-11.042,24.916-23.994a62.516,62.516,0,0,0,3.543-20.357,72.685,72.685,0,0,0-3.251-22.158Z" transform="translate(-767.049 -314.516)" fill="url(#linear-gradient-120)"/>
                <path id="Path_569" data-name="Path 569" d="M904.937,396.352a40.008,40.008,0,0,0-2.53-6.25h-.01a33.382,33.382,0,0,0-4.21-6.47c-4.83-5.73-11.131-8.85-17.511-7.85-11.769,1.83-19.329,16.99-16.859,33.86,2.439,16.87,13.98,29.049,25.75,27.24,6.9-1.08,12.359-6.731,15.189-14.62a35.61,35.61,0,0,0,1.581-6.06,40.956,40.956,0,0,0,.58-6.35,44.253,44.253,0,0,0-1.98-13.5Z" transform="translate(-767.049 -314.516)" fill="url(#linear-gradient-121)"/>
                <path id="Path_570" data-name="Path 570" d="M904.937,396.352a40.008,40.008,0,0,0-2.53-6.25h-.01a7.661,7.661,0,0,0-4.48-.69c-5.58.86-9.161,8.04-7.991,16.04,1.151,7.99,6.621,13.76,12.2,12.9a7.8,7.8,0,0,0,4.21-2.15,40.956,40.956,0,0,0,.58-6.35,44.253,44.253,0,0,0-1.98-13.5Z" transform="translate(-767.049 -314.516)" fill="url(#linear-gradient-122)"/>
                <path id="Path_571" data-name="Path 571" d="M961.966,459.733a3.782,3.782,0,0,0-3.781,3.782v10.112a3.781,3.781,0,0,0,7.563,0V463.515A3.783,3.783,0,0,0,961.966,459.733Z" transform="translate(-767.049 -314.516)" fill="url(#linear-gradient-123)"/>
                <path id="Path_572" data-name="Path 572" d="M962.795,459.733a3.783,3.783,0,0,0-3.782,3.782v10.112a3.781,3.781,0,0,0,7.563,0V463.515A3.782,3.782,0,0,0,962.795,459.733Z" transform="translate(-767.049 -314.516)" fill="#001a6c"/>
                <path id="Path_573" data-name="Path 573" d="M962.795,460.949a2.569,2.569,0,0,0-2.565,2.566v4.025a2.565,2.565,0,0,0,5.129,0v-4.025A2.568,2.568,0,0,0,962.795,460.949Z" transform="translate(-767.049 -314.516)" fill="#faf9fc"/>
                <path id="Path_574" data-name="Path 574" d="M901.589,381.814c-4.968-16.1-12.59-30.167-21.883-41.231-15.671-18.6-36.092-28.713-56.768-25.466-27.145,4.2-46.818,33.651-53.445,67.593,8.276-8.9,18.221-14.959,29.445-16.695,20.676-3.247,41.1,6.871,56.768,25.466,9.293,11.063,16.915,25.13,21.883,41.231a143.059,143.059,0,0,1,6.43,43.791,132,132,0,0,1-1.824,20.259C893.9,484.69,899.508,470,901.01,465.842a123.746,123.746,0,0,0,7.009-40.236,143.059,143.059,0,0,0-6.43-43.792Z" transform="translate(-767.049 -314.516)" opacity="0.8" fill="url(#linear-gradient-124)" style="mix-blend-mode: multiply;isolation: isolate"/>
            </g>
            <g id="Group_3042" data-name="Group 3042" transform="translate(261.923 179.324)">
                <path id="Path_575" data-name="Path 575" d="M514.375,468.166c-.845,5.627.5,6.993.5,6.993s7.259-3.173,12.09-2.53S515.219,462.54,514.375,468.166Z" transform="translate(-424.563 -313.431)" fill="#edf2fe"/>
                <path id="Path_576" data-name="Path 576" d="M619.31,607.769c-5.27-.671-19.686,6.809-28.7-5.366l-11.185,4.432s3.4,7.279,3.773,12.136.451,8.535,8.34,7.406,30.218-12.672,30.218-12.672S624.58,608.442,619.31,607.769Z" transform="translate(-424.563 -313.431)" fill="url(#linear-gradient-125)"/>
                <path id="Path_577" data-name="Path 577" d="M561.158,462.648s-30.987-14.267-39.643,1.476c-8.167,14.855,11.25,32.757,24.48,55.1,7.857,13.272,12.782,19.435,13.851,25.869,1.438,8.641,15.617,52.18,20.16,64.548,0,0,10-1.389,14.7-5.9,0,0-12.7-66.123-13.072-69.522C580.05,519.624,561.158,462.648,561.158,462.648Z" transform="translate(-424.563 -313.431)" fill="#03327d"/>
                <path id="Path_578" data-name="Path 578" d="M561.158,462.648s-30.987-14.267-39.643,1.476a15.4,15.4,0,0,0-1.883,6.4c12.16-3.606,30.295,3.272,30.295,3.272s24.689,50.937,27.272,65.393c.486,2.718,9.065,50.309,13.162,67.4a21.879,21.879,0,0,0,4.344-2.849s-12.7-66.123-13.072-69.522C580.05,519.624,561.158,462.648,561.158,462.648Z" transform="translate(-424.563 -313.431)" fill="url(#linear-gradient-110)"/>
                <path id="Path_579" data-name="Path 579" d="M563.29,390.555c-15.416-3.4-39.065,8.238-39.065,8.238-6.653,3.794-19.116,21.932-23.985,26.508s-8.654,4.657-8.654,4.657-2.686-.5-4.6,2.622-5.908,2.917-5.908,2.917.37,3.564,6.421,1.222,2.523,2.623,2.523,2.623-10.064,7.954-11.2,10.818,9.108-5.562,11.636-6.634,6.2-1.2,10.057-4.138,1.537-3.782,5.64-7.6,20.2-15.008,25.06-19.44,33.988-4.513,33.988-4.513S578.706,393.953,563.29,390.555Z" transform="translate(-424.563 -313.431)" fill="#ffcab8"/>
                <path id="Path_580" data-name="Path 580" d="M563.29,390.555c-15.416-3.4-39.065,8.238-39.065,8.238-6.653,3.794-19.116,21.932-23.985,26.508s-8.654,4.657-8.654,4.657a3.747,3.747,0,0,0-1.545.131,4.033,4.033,0,0,1-.036.757c-.689,4.286-4.666,6.069-8.483,5.663.644.835,2.208,1.669,5.978.21,6.05-2.342,2.523,2.623,2.523,2.623s-8.808,6.962-10.874,10.2a70.231,70.231,0,0,1,11.224-7.509c3.6-1.932,7.132-9.9,11.206-14.075,1.995-2.045,10.349-12.3,12.18-13.406,1.914-1.152,4.405-1.43,6.228-2.652,1.9-1.277,2.483-3.728,3.784-5.519a13.21,13.21,0,0,1,4.24-3.84c3.72-2.108,6.954-1.99,10.8-.485,8.534,3.342,16.9-1.971,25.706-1.9a3.562,3.562,0,0,1,3.78,3.707C571.084,399.486,573.374,392.777,563.29,390.555Z" transform="translate(-424.563 -313.431)" fill="#e7ac9a"/>
                <path id="Path_581" data-name="Path 581" d="M584.631,368.967c-6.312,8.994-19.5,5.444-19.5,5.444,14.974,5.184,30.4,23.791,30.4,23.791s-3.223-6.232-3.931-8.025,1.314-11.389,4.312-10.438,3.855,1.156,5.252.685c4.875-1.643,11.047-11.55,11.084-13.984.053-3.593,1.432-3.022,2.721-6.4a11.477,11.477,0,0,0,.533-7.232C613.62,341.624,589.635,361.836,584.631,368.967Z" transform="translate(-424.563 -313.431)" fill="#ffcab8"/>
                <path id="Path_582" data-name="Path 582" d="M584.631,368.967c-4.373,10.081-19.5,5.444-19.5,5.444,9.734,3.37,19.6,12.116,25.323,17.828-1.546-3.963-1.11-8.421,2.987-10.861.693-1.2,1.534-1.937,2.472-1.639.441.139.825.259,1.181.367a4.292,4.292,0,0,0,1.581-.728c1.646-1.447,2.306-3.838,3.856-5.421,2.087-2.134,5.1-2.8,7.172-4.947A22.837,22.837,0,0,0,612.379,365a5,5,0,0,1,1.176-2.389,7.9,7.9,0,0,0,.777-2.845,2.6,2.6,0,0,1,1.4-2.492,12.783,12.783,0,0,0-.222-4.46C613.62,341.624,584.631,368.967,584.631,368.967Z" transform="translate(-424.563 -313.431)" fill="#e7ac9a"/>
                <path id="Path_583" data-name="Path 583" d="M555,451.906c-16.949.339-29.267,38.478-33.506,55.906-4.818,19.8-1.019,35.483,11.1,27.19,11.871-8.121,40.832-31.8,45.343-57.1C579.457,469.37,572.959,451.547,555,451.906Z" transform="translate(-424.563 -313.431)" fill="url(#linear-gradient-110)"/>
                <path id="Path_584" data-name="Path 584" d="M555,451.906c-16.949.339-29.267,38.478-33.506,55.906-2.043,8.4-2.484,16.112-1.415,21.562,2.889.036,6.142-1.29,8.97-2.891.6-18.795,12.02-26.344,13.983-30.443,1.881-3.93,14.457-4.914,14.977-5.008,17.859-3.214,19.505-12.975,19.754-17.29C575.513,463.7,566.985,451.667,555,451.906Z" transform="translate(-424.563 -313.431)" fill="#03327d"/>
                <path id="Path_585" data-name="Path 585" d="M542.81,478.049c22.7,5.931,37.423-3.438,37.423-3.438l-6.595-38.005S520.111,472.117,542.81,478.049Z" transform="translate(-424.563 -313.431)" fill="url(#linear-gradient-128)"/>
                <path id="Path_586" data-name="Path 586" d="M564.534,375.56c-7.9-3.338-15.932,10.634-17.749,14.752s-7.167,33.219-10.06,37.394-21.471,29.162-24.82,36.747c-3.509,7.946,2.967,10.706,2.967,10.706s-3.749-15.073,14.595,2.786,42.851,5.129,42.851,5.129c-6.031-23.17,9.749-49.4,11.228-52.378s20.24-15.321,14.6-28.283S574.484,379.763,564.534,375.56Z" transform="translate(-424.563 -313.431)" fill="#fff"/>
                <path id="Path_587" data-name="Path 587" d="M546.424,474.321a113.5,113.5,0,0,0-8.551,9.65,33.163,33.163,0,0,0,12.8,3.413C549.687,483.4,549.2,471.392,546.424,474.321Z" transform="translate(-424.563 -313.431)" fill="#edf2fe"/>
                <path id="Path_588" data-name="Path 588" d="M588.217,396.022c-1.345-3.063-4.5-5.186-4.467-8.933a3.953,3.953,0,0,1,.086-.75,100.3,100.3,0,0,0-19.3-10.779c-7.9-3.338-15.932,10.634-17.749,14.752s-7.167,33.219-10.06,37.394-21.471,29.162-24.82,36.747c-2.982,6.752,1.244,9.758,2.582,10.509a4.079,4.079,0,0,1-.964-2.8c.052-3.151,5.88-12.739,13.559-16.244,4.009-1.83,8.484.667,12.594-1.024,5.691-2.341,18.1-3.106,18.571-3.022,4.528-.263,3.948-7.54,7.989-5.921.617.247,6.477,9.618,6.891,10.011,3.359-13.066,9.521-23.458,10.419-25.264a13.187,13.187,0,0,1,2.311-2.538c-2.34.4-4.614-.389-6.149-3.282-2.846-5.365-.231-14.783,2.215-19.853A36.248,36.248,0,0,1,588.217,396.022Z" transform="translate(-424.563 -313.431)" fill="#edf2fe"/>
                <path id="Path_589" data-name="Path 589" d="M598.55,411.894c-.1.41-.209.825-.319,1.242a14.637,14.637,0,0,0,.9-6.293A27.942,27.942,0,0,1,598.55,411.894Z" transform="translate(-424.563 -313.431)" fill="#edf2fe"/>
                <path id="Path_590" data-name="Path 590" d="M612.707,343.433S610.4,329.44,597.64,328.51c-31.027-2.264-36.822,22.711-39.894,25.9-2.926,3.04-8.48,2.751-16.876,7.327s-4.12,11.294-6.608,18.757-7.622,3.214-12.5,9.138,4.361,13.325-2.382,16.349-5.885,6.11-3.342,11.1.635,12.813.635,12.813c20.336,5.4,18.843-9.352,29.295-10.644s2.68-15.467,9.648-19.544,11.879-4.213,18.4-8.135c6.55-3.939,4.906-16.659,10.222-20.2s12.436.735,13.959-3.424,4.318-5.089,5.577-4.286-.989,5.225-.989,5.225c4.161-2.627,11.588-14.456,11.588-14.456-2.108,3.142-1.7,8.917-1.7,8.917s.078-1.892,3.037-5.584S620.318,347.787,612.707,343.433Z" transform="translate(-424.563 -313.431)" fill="url(#linear-gradient-129)"/>
                <path id="Path_591" data-name="Path 591" d="M597.011,325.167c-5.97-5-15.2-2.4-19.421-3.75s-10.85-11.26-20.22-6.874-7.1,12.356-12.209,14.216-12.26-3.693-17.949,2.557-2.765,15.906-10.54,16.888c0,0,9.064,13.463,20.8,7.266s15.112,1.51,22.866-5.921,8.626-7.112,16-3.568,12.31-5.387,16.637-1.631c1.966,1.708,7.04-3.37,8.132-5.582C602.676,335.584,602.981,330.165,597.011,325.167Z" transform="translate(-424.563 -313.431)" fill="url(#linear-gradient-129)"/>
                <path id="Path_592" data-name="Path 592" d="M600.749,333.46a17.948,17.948,0,0,1-.781,3.345,7.51,7.51,0,0,1-1.158,2.03,19.4,19.4,0,0,1-1.88,1.926,16.424,16.424,0,0,0-1.263,1.121,11.117,11.117,0,0,0-1.392,1.391,7.848,7.848,0,0,0,1.993.467,9.567,9.567,0,0,0,2.005-.147,6.756,6.756,0,0,0,3.668-2.271,5.642,5.642,0,0,0,1.172-4.445A4.768,4.768,0,0,0,600.749,333.46Z" transform="translate(-424.563 -313.431)" fill="url(#linear-gradient-131)"/>
                <path id="Path_593" data-name="Path 593" d="M464.922,481.978c-1.654-1.175-3.247-2.039-4.114-3.445-1.83-2.971-6.985-9.791-13.7-7.415-2.4.848-5.265,3.959-8.761,6.966-6.3,5.417-13.289,12.077-13.669,15.772-.589,5.749,2.039,10.164,7.156,7.926s9.736-6.349,13.6-7.121,10.9-.641,12.686.572S472.409,487.294,464.922,481.978Z" transform="translate(-424.563 -313.431)" fill="url(#linear-gradient-132)"/>
                <path id="Path_594" data-name="Path 594" d="M447.105,471.118c-2.4.848-5.265,3.959-8.761,6.966-6.3,5.417-13.289,12.077-13.669,15.772-.589,5.749,1.1,8.633,6.218,6.4s5.511-7.336,11.924-14.765c1.417-1.64,9.735-7.1,10.6-8.614C455.148,473.842,453.823,468.742,447.105,471.118Z" transform="translate(-424.563 -313.431)" fill="url(#linear-gradient-129)"/>
                <path id="Path_595" data-name="Path 595" d="M515.819,502.4c-29.929-11.589-41.094-14.078-53.193-22.02,0,0,5.779,7.088-9.165,13.827,10.3,3.651,48.434,30.751,55.236,36.046,12.014,9.352,20.475,7.763,24.173,4.171C537.424,530,547.158,514.538,515.819,502.4Z" transform="translate(-424.563 -313.431)" fill="url(#linear-gradient-110)"/>
                <path id="Path_596" data-name="Path 596" d="M515.819,502.4c-29.929-11.589-41.094-14.078-53.193-22.02,0,0,2.867,3.525-.68,7.967,11.193.658,43.829,32.964,58.551,33.608,2.576.113,9.925-7.418,11.622-10.264C528.535,507.3,516.67,502.732,515.819,502.4Z" transform="translate(-424.563 -313.431)" fill="#03327d"/>
                <path id="Path_597" data-name="Path 597" d="M597.593,406.18s-9.058-4.362-23.576,10.285-18.036,36.365-14.283,40.414c2.223,2.4,8.939,2,15.863-8.327S603.84,410.578,597.593,406.18Z" transform="translate(-424.563 -313.431)" fill="#ffcab8"/>
                <path id="Path_598" data-name="Path 598" d="M559.734,456.879c1.289,1.39,4.093,1.824,7.567-.057a3.779,3.779,0,0,1-1.248-1.569c-3.6-8.607,1.76-18.251,5.953-25.488,2.617-4.514,6.262-8.133,9.278-12.36,1.516-2.126,2.719-4.417,4.7-6.223,1.873-1.705,4.292-2.8,5.745-4.875a4.67,4.67,0,0,1,.546-.627c-4.087.48-10.362,2.819-18.259,10.785C559.5,431.111,555.981,452.83,559.734,456.879Z" transform="translate(-424.563 -313.431)" fill="#e7ac9a"/>
                <path id="Path_599" data-name="Path 599" d="M597.9,406.4c-14.387-8.1-29.4,13.19-30.791,15.033,2.647.117,15.731,2.5,23.038,7.652C596.359,419.408,601.566,408.459,597.9,406.4Z" transform="translate(-424.563 -313.431)" fill="#fff"/>
                <path id="Path_600" data-name="Path 600" d="M626.584,429c-3.113-1.5-4.381-.413-4.228-3.04s1.149-3.353-1.112-5.628-1.745.931-1.769,2.463c-.069,4.642-6.089,6.816-9.708,10.6S569.235,442,566.73,442.37c-4.929.72-11.711,8.752-5.759,14.629.3.3,9.392,3.057,39.277-9.972,4.072-1.775,9.927-5.011,17.078-4.9,11.6.175,7.563-4.145,7.563-4.145s3.5.05,1.249-2.879c0,0,4.615-.05,1.813-2.829C627.951,432.27,629.7,430.5,626.584,429Z" transform="translate(-424.563 -313.431)" fill="#ffcab8"/>
                <path id="Path_601" data-name="Path 601" d="M622.275,438.1c.083-2.026,1.884-9.124.772-9.491-.832-.273-9.124,7.37-10.382,7.39-3.119,6.685-5.42,5.691-12.537,7.387-2.876.684-5.064.907-7.721,2.3-3.427,1.8-6.783,3.627-10.354,5.151-3.632,1.549-5.451,1.624-9.427,1.564-9.417-.141-10.28-6.449-10.242-7.831-3.419,2.948-6.408,9.155-1.413,12.42,3.461,2.263,9.392,3.057,39.277-9.972,4.072-1.775,9.927-5.011,17.078-4.9,5.05.076,7.135-.7,7.88-1.6C623.97,439.6,622.207,439.777,622.275,438.1Z" transform="translate(-424.563 -313.431)" fill="#e7ac9a"/>
            </g>
            <g id="Group_3047" data-name="Group 3047" transform="translate(519.345 272.161)">
                <path id="Path_602" data-name="Path 602" d="M793.505,431.737c-3.569-2.848-9.91-.459-12.833-.384a111.39,111.39,0,0,1-15.819-.664c-6.656-.991-18.441,5.423-9.223,13.444,5.318,4.628,20.008,2.336,30.056.306C799.917,441.563,795.342,433.2,793.505,431.737Z" transform="translate(-682.085 -406.201)" fill="#fff"/>
                <path id="Path_603" data-name="Path 603" d="M787.216,436.987c-11.827,3.023-23.018,2.609-34.962,1-.105,1.846.834,3.934,3.376,6.146,5.318,4.628,20.008,2.336,30.056.306,11.735-2.372,10.682-8.467,8.921-11.357A18.507,18.507,0,0,1,787.216,436.987Z" transform="translate(-682.085 -406.201)" fill="#edf2fe"/>
                <path id="Path_604" data-name="Path 604" d="M745.963,486.123c-9.28,4.621-6.112,28.924-3.9,39.666,2.518,12.205,8.72,19.879,13.249,12.125,4.437-7.6,10.251-31.259,6.126-46.422C760.05,486.378,755.8,481.226,745.963,486.123Z" transform="translate(-682.085 -406.201)" fill="#03327d"/>
                <path id="Path_605" data-name="Path 605" d="M745.963,486.123c-9.28,4.621-6.112,28.924-3.9,39.666,1.067,5.177,2.843,9.556,4.858,12.288a11.623,11.623,0,0,0,4.2-3.944c-4.581-10.547-.247-17.705-.235-20.485.012-2.664,4.706-9.5,4.969-9.686,9.03-6.448,5.387-12.274,4.395-14.725C756.388,484.276,752.526,482.855,745.963,486.123Z" transform="translate(-682.085 -406.201)" fill="url(#linear-gradient-135)"/>
                <path id="Path_606" data-name="Path 606" d="M704.042,526.308a8.634,8.634,0,0,1-3.175-.827c-1.789-1.165-6.423-3.586-9.514-.514-1.1,1.1-1.875,3.565-3.02,6.141-2.065,4.643-6.384,13.373-6.245,15.865.215,3.876,5.6,5.514,7.844,2.939s4.72-10.057,6.653-11.494,5.857-3.206,7.161-3S709.571,527.288,704.042,526.308Z" transform="translate(-682.085 -406.201)" fill="url(#linear-gradient-132)"/>
                <path id="Path_607" data-name="Path 607" d="M691.618,524.715c-1.143,1.135-1.316,3.706-2.5,6.374-2.138,4.807-6.61,13.847-6.467,16.428.224,4.013,5.22,4.852,7.543,2.185s2.342-9.22,4-15.209c.366-1.323,3.625-5.583,3.625-8C697.817,524.283,694.819,521.535,691.618,524.715Z" transform="translate(-682.085 -406.201)" fill="#fff"/>
                <path id="Path_608" data-name="Path 608" d="M691.353,524.967c-1.1,1.1-1.875,3.565-3.02,6.141-2.065,4.643-6.384,13.373-6.245,15.865a3.9,3.9,0,0,0,6.925,2.338c2.243-2.577,2.127-9.5,3.728-15.281.354-1.278,3.523-6.47,3.606-7.534C696.511,524.368,694.444,521.9,691.353,524.967Z" transform="translate(-682.085 -406.201)" fill="url(#linear-gradient-129)"/>
                <path id="Path_609" data-name="Path 609" d="M737.517,524.283c-19.574,1.425-26.492.682-35.162.5,0,0,5.049,3-1.448,12.533,7.072.763,34.815,3.08,39.96,4.227,9.087,2.027,13.348-1.064,14.452-4.018C756.678,533.886,758.014,522.792,737.517,524.283Z" transform="translate(-682.085 -406.201)" fill="#03327d"/>
                <path id="Path_610" data-name="Path 610" d="M737.517,524.283c-19.574,1.425-26.492.682-35.162.5a6.581,6.581,0,0,1,1.709,5.827c6.359-2.564,32.849,6.755,41.154,3.26,1.455-.611,3.545-6.7,3.74-8.714C745.827,523.665,738.073,524.243,737.517,524.283Z" transform="translate(-682.085 -406.201)" fill="url(#linear-gradient-138)"/>
                <g id="Group_3044" data-name="Group 3044" transform="translate(136.84 110.949)">
                <path id="Path_611" data-name="Path 611" d="M842.062,517.236c-2.606-.894-5.213,5.7-5.461,6.019-.888,1.139-3.551,1.843-5.608,2.823a9.708,9.708,0,0,1-8.171.283l-3.9,9.586s3.873,1.37,7.041,2.851,6.928,5.65,9.133,2.842,2.833-8.311,4.987-12.441c1.9-3.651,4.883-9.295,3.6-11.193A5.755,5.755,0,0,0,842.062,517.236Z" transform="translate(-818.925 -517.151)" fill="#ffcab8"/>
                <path id="Path_612" data-name="Path 612" d="M842.062,517.234c-2.6-.894-5.212,5.7-5.46,6.018-.831,1.068-3.208,1.751-5.2,2.64a1.139,1.139,0,0,1-.819.373l-.007,0c-1.893-.229-2.152.68-2.152.68,2.859,2.968,1.329,6.949-2.377,8.265-1.941.689-2.045,1.88-1.712,2.857.547.236,1.1.479,1.63.726,3.168,1.483,6.931,5.65,9.132,2.845s2.836-8.315,4.992-12.441c1.9-3.654,4.882-9.3,3.6-11.191A5.828,5.828,0,0,0,842.062,517.234Z" transform="translate(-818.925 -517.151)" fill="url(#linear-gradient-139)"/>
                <g id="Group_3043" data-name="Group 3043" transform="translate(0 0.016)">
                    <path id="Path_613" data-name="Path 613" d="M818.925,535.947s1,.353,2.393.894c-.867-.357-1.69-.679-2.374-.94Z" transform="translate(-818.925 -517.167)" fill="#001223" opacity="0.4"/>
                    <path id="Path_614" data-name="Path 614" d="M842.062,517.236a1.534,1.534,0,0,0-.328-.069.507.507,0,0,1,.12.109c1.286,1.9-1.693,7.542-3.6,11.194-2.153,4.13-2.782,9.633-4.987,12.44a2.289,2.289,0,0,1-2.426.889c1.631.9,3.142,1.26,4.256-.159,2.2-2.808,2.833-8.311,4.987-12.441,1.9-3.651,4.883-9.295,3.6-11.193A5.755,5.755,0,0,0,842.062,517.236Z" transform="translate(-818.925 -517.167)" fill="#fff"/>
                </g>
                </g>
                <path id="Path_615" data-name="Path 615" d="M790.72,498.995c-23.15-17.33-31.63-21.83-41-15.94-4.6,2.89-6.58,11.69-4.81,16.82a5.43,5.43,0,0,0,.721,1.4c2.059,2.93,6.989,4.32,13.189,6.68,8.16,3.11,26.37,4.08,32.24,9.231,8.35,7.309,20.1,14.689,30.1,20.6a2.789,2.789,0,0,0,.37-.14,3.037,3.037,0,0,0,.36-.18c.1-.06.19-.12.29-.18a3.788,3.788,0,0,0,.4-.3c.17-.14.33-.3.49-.46.11-.11.21-.23.32-.35.13-.15.26-.319.39-.489.15-.21.31-.431.45-.661.14-.21.28-.44.41-.66.14-.24.27-.49.4-.74a32.181,32.181,0,0,0,2.341-6.359s-12.761-7.7-29.741-23.671C795.57,501.645,793.25,500.885,790.72,498.995Z" transform="translate(-682.085 -406.201)" fill="#03327d"/>
                <path id="Path_616" data-name="Path 616" d="M769.493,503.424c2.09.3-17.306,5.684-24.7-3.914-.366-.475.5,1.369.838,1.765,2.059,2.93,6.989,4.32,13.189,6.68,8.16,3.11,26.37,4.08,32.24,9.231,8.49,7.149,20.1,14.689,30.1,20.6a2.789,2.789,0,0,0,.37-.14,3.037,3.037,0,0,0,.36-.18c.1-.06.19-.12.29-.18a3.788,3.788,0,0,0,.4-.3c.17-.14.33-.3.49-.46.11-.11.21-.23.32-.35.13-.15.26-.319.39-.489.15-.21.31-.431.45-.661.14-.21.28-.44.41-.66.14-.24.27-.49.4-.74-8.49-5.03-18.35-11.31-25.26-17.74C794.86,511.3,769.493,503.424,769.493,503.424Z" transform="translate(-682.085 -406.201)" fill="url(#linear-gradient-140)"/>
                <g id="Group_3045" data-name="Group 3045" transform="translate(101.945 17.012)">
                <path id="Path_617" data-name="Path 617" d="M827.324,423.631c-3.025.9-3.642-.025-4.461.1s-1.773-.535-3.067.173-6.563,3.793-6.563,3.793.252,3.41,2.631,3.926c0,0,1.993-1.244,3.236-.167s4.766-1,6.618-2.2,2.226-1.92.721-1.989-3.142,1.739-4.125,1.209c0,0,4.877-2.95,6.142-3.217s3.034-.49,3.214-1.137S830.348,422.727,827.324,423.631Z" transform="translate(-784.03 -423.213)" fill="#ffcab8"/>
                <path id="Path_618" data-name="Path 618" d="M824.341,425.706c-1.9.987-3.325,2.934-5.4,3.525-1.345.382-3.79.375-4.556-1.1a1.9,1.9,0,0,1-.22-.979l-.934.546s.252,3.41,2.631,3.926c0,0,1.993-1.244,3.236-.167s4.766-1,6.618-2.2,2.226-1.92.721-1.989-3.142,1.739-4.125,1.209c0,0,4.877-2.95,6.142-3.217s3.034-.49,3.214-1.137a.464.464,0,0,0-.171-.467C829.163,424.593,826.646,424.508,824.341,425.706Z" transform="translate(-784.03 -423.213)" fill="#e7ac9a"/>
                <path id="Path_619" data-name="Path 619" d="M814.847,425.709c-5.975,1.763-22.9,3.822-26.256,5.9-8.463,5.232-3.043,10.383-.446,10.69,10.169,1.2,29.262-10.035,29.262-10.035C818.377,427.5,814.847,425.709,814.847,425.709Z" transform="translate(-784.03 -423.213)" fill="#fff"/>
                <path id="Path_620" data-name="Path 620" d="M816.866,429.84c-2.6,2.131-6.473,2.786-9.606,3.849a40.036,40.036,0,0,1-11.731,2.544,2.289,2.289,0,0,1-1.13-.241,19.368,19.368,0,0,1-7.832,1.71,19.86,19.86,0,0,1-2.533-.179,5.192,5.192,0,0,0,4.111,4.774c10.169,1.2,29.262-10.035,29.262-10.035a7,7,0,0,0,0-3.028A2.513,2.513,0,0,1,816.866,429.84Z" transform="translate(-784.03 -423.213)" fill="#edf2fe"/>
                </g>
                <path id="Path_621" data-name="Path 621" d="M785.172,406.881c-4.761,2.164-11.469-3.257-16.617,1.014s-2.046,10.605-3.117,15.154-3.053,4.485-3.053,4.485l10.246,5.749s.4-1.8,1.91-1.3a7.607,7.607,0,0,0,2.7.394c5.378-4.915,4.742-8.31,4.847-9.653s1.457-1.914,2.008-4.457-.4-6.529-.4-6.529A3.926,3.926,0,0,0,785.172,406.881Z" transform="translate(-682.085 -406.201)" fill="#ffcab8"/>
                <path id="Path_622" data-name="Path 622" d="M768.554,407.895c-4.433,3.678-2.75,8.886-2.881,13.167a.988.988,0,0,1,.65.249c2.353,1.974,6.256,2.937,6.247,6.238,0,1.007-1.658,3.344-1.978,4.59l2.039,1.143s.4-1.8,1.91-1.3a7.607,7.607,0,0,0,2.7.394c5.378-4.915,4.742-8.311,4.847-9.653s1.457-1.914,2.008-4.457-.4-6.529-.4-6.529a3.926,3.926,0,0,0,1.478-4.852C780.411,409.045,773.7,403.624,768.554,407.895Z" transform="translate(-682.085 -406.201)" fill="#e7ac9a"/>
                <path id="Path_623" data-name="Path 623" d="M768.554,407.895c-5.147,4.27-2.045,10.605-3.116,15.154a13.61,13.61,0,0,1-.392,1.357c.2,0,6.035,1.6,8.025-.434,1.005-1.029,1.813-2.144,2.89-3.136,2.169-2,3.2-1.776,3.647.257a.225.225,0,0,0,.44,0c.6-2.723,2.258-3.643,2.407-5.672.1-1.321.119-4.059,1.3-3.734a3.928,3.928,0,0,0,1.417-4.8C780.411,409.045,773.7,403.624,768.554,407.895Z" transform="translate(-682.085 -406.201)" fill="url(#linear-gradient-141)"/>
                <path id="Path_624" data-name="Path 624" d="M771.273,431.738c-4.761-1.02-6.875-5.109-7.985-5.271-1.163-.17-4.8,1.64-7.289,5.417,0,0,16.534,4.06,21.944,8.9C775.88,437.839,775.682,432.684,771.273,431.738Z" transform="translate(-682.085 -406.201)" fill="#fff"/>
                <path id="Path_625" data-name="Path 625" d="M769.668,434.282c-.2-.115-.416-.232-.628-.349-.354-.2-.727-.393-1.117-.6s-.782-.4-1.182-.6a39.408,39.408,0,0,0-4.316-1.863c-.2-.077-.39-.143-.583-.2a10.806,10.806,0,0,0-2.7-.585,2.939,2.939,0,0,0-.438-.006c-2.467.137-7.538,4.958-9.083,9.846-2.866,9.091.269,23.884.111,31.19s-10.707,5.653-7.689,19.673c0,0,.875-2.394,16.509,3.375,3.121,1.144,6.825,2.611,11.223,4.5l2.114-10.1s1.162,9.391,1.637,10.464c0,0,7.061-3.6,8.61-6.743,0,0,1.848-40.281-2.894-49.431C777.313,439.133,776.488,438.164,769.668,434.282Z" transform="translate(-682.085 -406.201)" fill="url(#linear-gradient-142)"/>
                <path id="Path_626" data-name="Path 626" d="M766.741,432.728a39.408,39.408,0,0,0-4.316-1.863c-.2-.077-.39-.143-.583-.2a10.806,10.806,0,0,0-2.7-.585,2.939,2.939,0,0,0-.438-.006c-2.467.137-7.538,4.958-9.083,9.846-2.866,9.091.269,23.884.111,31.19s-10.707,5.653-7.689,19.673c0,0,.875-2.394,16.509,3.375-1.466-11.5,4.855-14.266,5-21.03.159-7.308-9.393-7.86-5.29-25.429,1.4-5.961,8.531-11.9,10.773-13.766-.354-.2-.727-.393-1.117-.6S767.141,432.93,766.741,432.728Z" transform="translate(-682.085 -406.201)" opacity="0.55" fill="url(#linear-gradient-143)" style="mix-blend-mode: screen;isolation: isolate"/>
                <path id="Path_627" data-name="Path 627" d="M766.159,474.1c-1.819-.745-6.585-2.779-6.585-2.779s-7.1.243-3.57,3.543a24.973,24.973,0,0,1,4.556,5.69c.8.665,2.616.954,3.584,1.416a9.488,9.488,0,0,0,4.293.539c1.223-.1,2-2.216,1.451-2.563-.483-.3-1.8.721-1.8.721s-.537-.6-1.718-1.657-.246-2.881,1.073-1.106,4.433,1.725,2.754.218S767.977,474.839,766.159,474.1Z" transform="translate(-682.085 -406.201)" fill="#ffcab8"/>
                <path id="Path_628" data-name="Path 628" d="M768.742,478.075c-1.608-2.025-3.639-1.8-3.913-.7-.1.4.7,1.7.9,2.14.574,1.279-1.981.259-3.089-.105-3.295-1.084,1.432,2.516,1.509,2.553a9.488,9.488,0,0,0,4.293.539c1.223-.1,2-2.216,1.451-2.563-.483-.3-1.8.721-1.8.721s-.537-.6-1.718-1.657-.246-2.881,1.073-1.106,4.433,1.725,2.754.218C770.1,478.032,769.27,478.74,768.742,478.075Z" transform="translate(-682.085 -406.201)" fill="#e7ac9a"/>
                <path id="Path_629" data-name="Path 629" d="M746.322,449.439c-7.9-6.045-10.053.61-9.716,3.579,1.319,11.624,19.356,22.06,19.356,22.06,4.219-.4,4.853-4.636,4.853-4.636C757.041,465.486,749.459,451.84,746.322,449.439Z" transform="translate(-682.085 -406.201)" fill="#edf2fe"/>
                <path id="Path_630" data-name="Path 630" d="M746.322,449.439c-7.344-5.618-9.719-.268-9.748,2.9a1.421,1.421,0,0,1,.32.122,55.478,55.478,0,0,1,12.549,9.517c2.05,2,3.83,4.223,5.712,6.379,1.484,1.7,3.078,3.17,4.072,5.2a6.342,6.342,0,0,0,1.588-3.116C757.041,465.486,749.46,451.84,746.322,449.439Z" transform="translate(-682.085 -406.201)" fill="#fff"/>
                <path id="Path_631" data-name="Path 631" d="M775.247,439.527c-5.765-4.058-20.144-.272-29.93,2.778-13.86,4.32-8.453,12.165-6.475,13.435,3.842,2.468,9.9-.558,12.8-.932a111.058,111.058,0,0,1,15.8-.962C774.173,454.148,785.238,446.56,775.247,439.527Z" transform="translate(-682.085 -406.201)" fill="#fff"/>
                <path id="Path_632" data-name="Path 632" d="M776.258,446.643a9.036,9.036,0,0,1-5.418,2.887c-1.947.292-3.263-.391-5,.619-1.793,1.041-4.418.922-5.6-1.04a3.837,3.837,0,0,0-1.851-.4c-1.264.476-6.32,2.048-7.591,2.474-1.108.371-3.072-.083-3.51.1-2.168.892-4.1,4.031-6.485,3.489-.6.346-1.2.7-1.784,1.07,3.849,2.274,9.775-.666,12.633-1.034a111.058,111.058,0,0,1,15.8-.962c6.276.283,16.334-6.315,9.509-12.914A5.193,5.193,0,0,1,776.258,446.643Z" transform="translate(-682.085 -406.201)" fill="#edf2fe"/>
                <g id="Group_3046" data-name="Group 3046" transform="translate(93.587 12.813)">
                <path id="Path_633" data-name="Path 633" d="M778.067,419.092c-.064.038-1.59.995-2.381,4.339a.537.537,0,0,0,.4.646l.045.01a.54.54,0,0,0,.6-.408c.668-2.826,1.862-3.649,1.9-3.672a.537.537,0,0,0-.563-.915Z" transform="translate(-775.672 -419.014)" fill="#ffcab8"/>
                </g>
            </g>
            </g>
        </g>
    </svg>
</template>