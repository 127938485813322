export default  {
    data(){
        return {
            _modelValue: this.modelValue
        }
    },
    watch: {
        modelValue(value){
            this._modelValue = value;
        },
        _modelValue(value){
            this.$emit('update:modelValue', value);
        }
    }
}