<template>

  <div :class="disabledClass"
       class="rounded shadow-sm inline-block bg-white flex flex-col p-0.5 relative overflow-visible __main_wrapper">

    <label class="w-full font-semibold pb-0" :class="getSize" v-if="$slots.label || label">
      <slot name="label">{{ label }}</slot>
    </label>

    <select
        ref="select"
        v-model="_modelValue"
        v-bind="{
        ...$attrs,
        ...{
          class: inputClass + ' w-full bg-transparent outline-none z-4 pt-0 ' + getSize}
        }
      " style="outline: none"
    >

      <option value="">{{ defaultItem }}</option>

      <option v-for="item in get_items" :key="getKey(item)" :value="getKey(item)">
        <slot name="selection-item" :item="item">
          {{ getValue(item) }}
        </slot>
      </option>

    </select>

    <div class="bg-red-600">

      <div @focus="handleSelectionFocus"
           @blur="handleSelectionBlur"
           class="absolute inset-0 bg-transparent border hover:border-green-400 focus:border-green-700 rounded cursor-pointer __wrapper_selection_dropdown"
           tabindex="-1">

      </div>

      <div class="absolute border rounded-b  border-gray-600 left-0 right-0 bg-white  pb-2 z-50 top-full -mt-1"
           :class="{'hidden': !dropdownShown, 'block': dropdownShown, 'py-1.5 px-3 shadow-lg':searchable, 'px-2': !searchable }"
           tabindex="-1"
           @focus="handleSelectionFocus"
           @blur="handleSelectionBlur"
           @click="handleDropdownClick"
      >
        <template v-if="searchable">
          <label class="text-gray-400 italic text-sm">Type to Search</label>
          <input-text ref="inputSearch" v-model="searchInputValue" @focus="handleSelectionFocus"
                      @blur="handleSelectionBlur"></input-text>
        </template>
        <div class="border overflow-y-auto  relative mt-2 text-xs"
             :class="{'shadow ': filteredItems.length && searchable}">

          <div class="w-full flex justify-center" v-if="remoteSearchUrl">

            <pagination
                v-if="filteredItems.length"
                navigation-type="button"
                :links="paginationLinks"
                v-model="page_number"
                :disabled="loading"
                class="pt-1.5 pb-2"
                @focus="handleSelectionFocus"
                @blur="handleSelectionFocus"></pagination>
          </div>

          <ul class="overflow-y-auto relative" :class="{'border ': filteredItems.length}"
              style="max-height:150px; min-height: 30px;">
            <template v-if="filteredItems.length">
              <li
                  v-for="item in filteredItems"
                  :key="getKey(item)"
                  class="border-b py-1.5  px-3 focus:bg-green-100 "
                  :data-item-key="`${getKey(item)}`"
                  :class="{'bg-gray-200 cursor-default': itemIsSelected( item ), 'hover:bg-gray-100 cursor-pointer ': !itemIsSelected( item ) }"
                  @click="selectItem(item)"
                  @focus="handleSelectionFocus"
                  @blur="handleSelectionBlur"
              >
                <slot name="selection-item" :item="item">{{ `${getValue(item)}` }}</slot>
              </li>
            </template>
            <template v-else>
              <li class="text-center italic text-sm text-gray-500 py-10 italic">No Result Found</li>
            </template>
          </ul>
        </div>
      </div>
    </div>

    <div class="absolute bottom-0 top-0 right-9 flex items-center" v-if="clearable">
      <button class="text-2xl top-6 text-gray-400 hover:text-gray-600" @click.prevent="emitModelValue('')">&times;
      </button>
    </div>

  </div>

</template>

<script>
import Spinner from "../spinner";
import {getColor} from "../../core/color";
import PasswordEye from "./password-eye";
import InputText from "./input-text";
import Overly from "../overly";
import Second from "../../views/public/home/components/slider/second";
import modelValueMixins from "../../mixins/model-value-mixins";
import Btn from "../btn";
import Pagination from "../pagination";
import axios from "../../store/axios";

export default {
  emits: ['update:modelValue'],
  props: {
    modelValue: {type: String, default: ''},
    items: {type: Array, default: [], required: true},
    itemKey: {type: String, default: 'id'},
    valueKey: {type: String, default: 'name'},
    defaultItem: {type: String, default: '--Select--'},
    searchable: {type: Boolean, default: false},
    inputClass: {type: String, default: ''},
    href: {type: String},
    remoteSearchUrl: {type: String, default: ''},
    disabled: {type: Boolean, default: false},
    placeholder: {type: String, default: ''},
    variant: {type: String, default: 'primary'},
    size: {type: String, default: "base"},
    label: {type: String, default: null},
    clearable: {type: Boolean, default: false}
  },
  components: {Pagination, Btn, Second, Overly, PasswordEye, Spinner, InputText},
  mixins: [modelValueMixins],
  data() {
    return {
      searchInputValue: '',
      dropdownShown: false,
      dropdownTimer: 0,
      selectedItems: [],
      paginationLinks: [],
      page_number: 1,
      filteredItems: [],
      loading: false,
      search_debouncing_timer: 0,
    }
  },
  name: "input-dropdown",
  created() {
    this.loadItems();
  },
  computed: {
    selectedItem() {
      if (Array.isArray(this.items) || (this.remoteSearchUrl && Array.isArray(this.filteredItems))) {
        let items = Array.isArray(this.items) ? this.items : null;
        if (!items) {
          items = this.filteredItems;
        }

        return items.find(item => this.getKey(item) === this._modelValue);
      }

      return null;

    },
    get_items() {
      if (this.remoteSearchUrl) {
        return this.filteredItems;
      }

      if (Array.isArray(this.items.data)) {

        return this.items.data;
      } else if (Array.isArray(this.items)) {
        return this.items;
      }

      return [];
    },
    getInputType() {

      if (this.type === 'password' && this.passwordEye) {
        return this.password_eye === 'on' ? 'text' : 'password';
      }
      return this.type;
    },
    getSize() {
      // return 'text-base px-2 py-2 ';

      switch (this.size) {
        case "xs":
          return 'text-xs px-1 py-px ';
        case "sm":
          return 'text-sm px-2 py-0.5 ';
        case "md":
          return 'text-base px-2 py-1 ';
        case "lg":
          return 'text-lg px-3 py-1.5 ';
        case "xl":
          return 'text-xl px-3 py-2 ';
        default:
          return 'text-base px-2 py-1'
      }

    },
    getColor() {
      return getColor(this.variant, {type: 'input'});
    },
    disabledClass() {
      return (this.disabled ? ' bg-gray-200 text-white pointer-events-none ' : ' ' + this.getColor);
    }
  },


  methods: {
    async loadItems(reloadType) {
      this.loading = true;

      if (this.remoteSearchUrl) {

        if (this.page_number > 1 && reloadType === 'search') {
          this.page_number = 1;
        }

        // console.log('this.selectedItems',this._modelValue)

        let r = await axios().get(this.remoteSearchUrl, {
          params: {
            page: this.page_number,
            search: this.searchInputValue,
            selected_id: this._modelValue
          }
        }).finally(() => this.loading = false)

        const result = ((r.data || {}))
        this.filteredItems = result.data;
        this.paginationLinks = result.links;

        console.log({r: result.data});

        // return new Promise((resolve, reject) => {
        //   resolve({data:[{id: 4, name: 'rr'}]});
        // });

      } else {
        if (Array.isArray(this.items.data)) {
          this.paginationLinks = this.items.links;
          this.filteredItems = this.items.data;

        } else if (Array.isArray(this.items)) {

          const items = this.items;
          if (!this.searchInputValue) {
            this.filteredItems = this.items;
          }

          this.filteredItems = this.items.filter((item) => {
            try {
              return (new RegExp(this.searchInputValue, 'id')).exec(this.getValue(item));
            } catch (e) {
              return true;
            }
          });

        }
      }

    },
    emitModelValue(value) {
      this._modelValue = value;
    },
    getKey(item) {
      return typeof item == 'object' && item ? item[this.itemKey] : item;
    },
    getValue(item) {
      return typeof item == 'object' && item ? item[this.valueKey] : item;
    },

    selectItem(item) {

      if (!this.itemIsSelected(item)) {

        const prevValues = this._modelValue;

        if (this.$refs.select.multiple) {
          const items = Array.isArray(prevValues) ? prevValues : [prevValues];
          items.push(item);

        } else {
          const keyValue = this.getKey(item);
          this.emitModelValue(keyValue);
          this.$emit('change', keyValue);
          this.dropdownShown = false;
        }

      }

    },

    itemIsSelected(item) {
      if (this.selectedItem && item) {
        return this.selectedItem[this.itemKey] === item[this.itemKey];
      }
      return false;
    },

    handleDropdownClick(e) {
    },

    handleSelectionFocus(e) {
      e.preventDefault();

      if (!this.disabled) {
        clearTimeout(this.dropdownTimer);

        this.dropdownShown = true;
        if (this.searchable) {
          this.$refs.inputSearch.getInput.focus()
        }
      }
    },

    handleSelectionBlur(e) {
      this.dropdownTimer = setTimeout(this.hideDropdown, 100);
    },

    hideDropdown() {
      this.dropdownShown = false;
    },

    itemSelected() {

      const options = this.$refs.select.querySelectorAll('option');
      this.selectedItems = [];

      options.forEach(opt => {
        if (opt.selected) {
          this.selectedItems.push(opt.value);
        }
      });

    }
  }
  ,
  watch: {
    page_number() {
      this.loadItems()
    },
    items() {
      this.loadItems();
    },
    searchInputValue() {
      clearTimeout(this.search_debouncing_timer);
      this.search_debouncing_timer = setTimeout(this.loadItems, 300, 'search')
    }
  }

}
</script>

<style scoped>

select {
  appearance: none;
  background-position: right 0.5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  padding-right: 2.5rem;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
}

select:focus {
  outline: none;
}

.__main_wrapper {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 30 20'%3E%3Cpath stroke='%2371717a' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3E%3C/svg%3E");
  background-position: right;
  background-repeat: no-repeat;
  background-size: 35px;
  /*background-position-x: 10px;*/
}

</style>