<template>
    <div class="w-full flex md:flex-row flex-col justify-between items-center md:px-4 gap-4 md:gap-8 lg:gap-16">
        <div class="w-full max-w-xs md:max-w-md  lg:max-w-lg order-2 md:order-1 text-center md:text-left">

          <h1 class="text-blue-600 text-2xl md:text-3xl lg:text-4xl font-bold leading-tight">
            <p class="block">আপনি কি চাকুরি প্রত্যাশী?</p>
          </h1>

          <p class="text-gray-700 text-sm lg:text-base my-4 md:my-8 text-justify">
              বিসিএস অথবা যেকোন প্রতিযোগিতা মূলক পরীক্ষায় নিজেকে এগিয়ে রাখতে আপনার পাশে আছে ইউনিভার্সাল বিসিএস
          </p>

          <router-link v-if="!isLoggedIn( )" class="px-7 py-1 rounded-sm text-white bg-orange-600" to="/join">
              Join Free
          </router-link>
          <router-link v-else class="px-4 py-1.5 rounded-sm text-white bg-blue-600" to="/courses">
              Courses
          </router-link>
        </div>
        <div class="w-full max-w-xs md:max-w-md lg:max-w-lg md:order-2 order-1">
          <second-banner class="w-full" />
        </div>
    </div>
</template>

<script>
import SecondBanner from './svg/second.vue';
export default {
    components: {
        SecondBanner
    }
}
</script>